import { styled } from '@mui/material/styles';
import MuiModal from '@mui/material/Modal';

const Modal = styled(MuiModal)(() => ({
  '.MuiBox-root': {
    borderRadius: '20vh',
  },
}));

export default Modal;
