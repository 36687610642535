import React, { FC, useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'components/Button';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Components
import ScreenView from 'components/ScreenView';

// Styles
import classes from './ShowBlitzAnswers.module.scss';

interface IProps {
  isModerator?: boolean;
  isPlayer?: boolean;
}

const ShowBlitzAnswers: FC<IProps> = ({ isModerator, isPlayer }) => {
  const [isShowQuestionsStep, setIsShowQuestionStep] = useState<boolean>(false);

  const {
    questionIndex,
    isQuestionSent,
    blitzTheme,
    isAnswerSent,
    isBlitzStep,
    isTableAvailable,
  } = sessionStore;

  const { socket } = useContext(SocketContext);

  const handleSend = useCallback(() => {
    if (isQuestionSent) {
      socket.emit(SocketEmitEvent.moderator_sendBlitz, {
        data: { isQuestionSent, isAnswerSent: true },
      });

      return;
    }

    socket.emit(SocketEmitEvent.moderator_sendBlitz, {
      data: { isQuestionSent: true, isAnswerSent: false },
    });
  }, [isQuestionSent]);

  const handleNext = useCallback((cIsShowQuestionsStep: boolean) => {
    if (
      !cIsShowQuestionsStep &&
      !(isQuestionSent && isAnswerSent && isTableAvailable)
    ) {
      setIsShowQuestionStep(!cIsShowQuestionsStep);

      return;
    }

    socket.emit(SocketEmitEvent.moderator_showBlitz);
  }, []);

  return (
    <>
      <ScreenView
        isShowQuestionsStep={isShowQuestionsStep}
        isModerator={isModerator}
        isPlayer={isPlayer}
      />
      {isModerator &&
      isShowQuestionsStep &&
      !isQuestionSent &&
      !isAnswerSent ? (
        <Button
          variant="contained"
          className={classes.Button}
          onClick={handleSend}
        >
          Показать вопрос
        </Button>
      ) : null}
      {isModerator && isShowQuestionsStep && isQuestionSent && !isAnswerSent ? (
        <Button
          variant="contained"
          className={classes.Button}
          onClick={handleSend}
        >
          Показать ответ
        </Button>
      ) : null}
      {isModerator &&
      (!isShowQuestionsStep || (isQuestionSent && isAnswerSent)) ? (
        <span className={classes.Button}>
          {isQuestionSent &&
          isAnswerSent &&
          Number(questionIndex) + 1 === blitzTheme?.questions.length &&
          !isTableAvailable &&
          isBlitzStep ? (
            <p className={classes.Placeholder}>
              Модератор еще проверяет ответы
            </p>
          ) : null}
          <Button
            variant="contained"
            disabled={
              isQuestionSent &&
              isAnswerSent &&
              isBlitzStep &&
              !isTableAvailable &&
              Number(questionIndex) + 1 === blitzTheme?.questions.length
            }
            onClick={() => handleNext(isShowQuestionsStep)}
          >
            Далее
          </Button>
        </span>
      ) : null}
    </>
  );
};

export default observer(ShowBlitzAnswers);
