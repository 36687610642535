import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import ReactPlayer from 'react-player';

// Components
import Loading from 'components/Loading';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Styles
import { observer } from 'mobx-react-lite';
import classes from './VideoView.module.scss';

interface IProps {
  youtubeId?: string;
  isModerator?: boolean;
}

const VideoView: FC<IProps> = ({ youtubeId, isModerator = false }) => {
  const [isVideoFullscreen, setVideoFullscreen] = useState(false);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [isVideoMuted, setVideoMuted] = useState(false);
  const videoRef = useRef<ReactPlayer>(null);

  const { mediaStatus, mediaTime } = sessionStore;

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (isModerator || !videoRef.current) {
      return;
    }

    if (mediaStatus === 'played') {
      setVideoFullscreen(true);
      setVideoPlaying(true);
      setVideoMuted(false);

      if (mediaTime > 0) {
        videoRef.current.seekTo(mediaTime);
      }
    }

    if (mediaStatus === 'paused') {
      setVideoFullscreen(false);
      setVideoPlaying(false);
      setVideoMuted(true);

      if (mediaTime > 0) {
        videoRef.current.seekTo(mediaTime);
      }
    }

    if (mediaStatus === 'ended') {
      setVideoFullscreen(false);
      setVideoPlaying(false);
      setVideoMuted(true);
    }
  }, [mediaStatus, mediaTime]);

  const handleVideoPlayed = useCallback(() => {
    if (!isModerator || !videoRef.current) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_playMedia, {
      data: { status: 'played', time: videoRef.current.getCurrentTime() },
    });
  }, [videoRef.current]);

  const handleVideoPaused = useCallback(() => {
    if (!isModerator || !videoRef.current) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_playMedia, {
      data: { status: 'paused', time: videoRef.current.getCurrentTime() },
    });
  }, [videoRef.current]);

  const handleVideoEnded = useCallback(() => {
    if (!isModerator) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_playMedia, {
      data: { status: 'ended' },
    });
  }, []);

  return (
    <div
      className={clsx(classes.VideoWrapper, {
        [classes.FullScreenVideo]: isVideoFullscreen,
      })}
    >
      {typeof youtubeId === 'undefined' ? (
        <Loading />
      ) : (
        <ReactPlayer
          className={classes.Video}
          ref={videoRef}
          onPlay={handleVideoPlayed}
          onPause={handleVideoPaused}
          onEnded={handleVideoEnded}
          muted={isVideoMuted}
          playing={isVideoPlaying}
          controls
          url={`https://www.youtube.com/watch?v=${youtubeId}`}
        />
      )}
    </div>
  );
};

export default observer(VideoView);
