import React, { FC } from 'react';
import cx from 'clsx';

import classes from './Logo.module.scss';

interface IProps {
  className?: string;
}

const Logo: FC<IProps> = ({ className }) => {
  return <div className={cx(classes.Root, className)} />;
};

export default Logo;
