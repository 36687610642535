import { useEffect } from 'react';
import { toast } from 'react-toastify';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { SocketCtx, SocketHandlerEvent } from 'types/socket';
import { Notification, SessionData } from 'types/game';

interface PatchedData {
  data: Partial<SessionData>;
}

interface PatchedNotification {
  data: Partial<Notification>;
}

export const useRealtime = (socket?: SocketCtx): void => {
  useEffect(() => {
    if (socket?.isInit) {
      socket?.subscribe(
        SocketHandlerEvent.game_updatedData,
        ({ data }: PatchedData) => {
          // console.log('subscription: game_updatedData', data);

          sessionStore.updateData(data);
        }
      );

      socket?.subscribe(
        SocketHandlerEvent.game_notification,
        ({ data }: PatchedNotification) => {
          if (!data.teamName) {
            return;
          }

          console.log('notification: game_notification', data);

          if (data.status === 'connected') {
            toast.success(`${data.teamName} снова подключился к сессии`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }

          if (data.status === 'disconnected') {
            toast.error(`${data.teamName} отключился от сессии`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }

          if (data.status === 'leftPage') {
            toast.warning(`${data.teamName} пошел гуглить`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }

          if (data.status === 'comeBackPage') {
            toast.warning(`${data.teamName} вернулся на страницу`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        }
      );
    }

    return () => {
      socket?.unsubscribe(SocketHandlerEvent.game_updatedData);
      socket?.unsubscribe(SocketHandlerEvent.game_notification);
    };
  }, [socket?.isInit]);
};
