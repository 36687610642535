import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'components/Button';
import Grid from '@mui/material/Grid';

// Stores
import sessionStore from 'stores/sessionStore';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Types
import { SocketEmitEvent } from 'types/socket';

// Components
import ListItem from './components/ListItem';
import AnswersArray from './components/AnswersArray';

// Styles
import classes from './CheckAnswers.module.scss';

interface IProps {
  themeIndex: number | 'blitz' | undefined;
  setThemeIndex: Dispatch<SetStateAction<number | 'blitz' | undefined>>;
}

const CheckAnswers: FC<IProps> = ({
  themeIndex: localThemeIndex,
  setThemeIndex: setLocalThemeIndex,
}) => {
  const { socket } = useContext(SocketContext);

  const {
    teamNameAnswers,
    themeIndex,
    isBlitzStep,
    isShowAnswersStep,
    isTableAvailable,
    questionIndex,
    questionsLength,
    isQuestionSent,
    isAnswerSent,
    selectedOrderTheme,
    blitzTheme,
    allThemes,
  } = sessionStore;

  const allQuestions = useMemo(() => {
    const themeId = selectedOrderTheme[themeIndex || 0];

    return isBlitzStep
      ? blitzTheme?.questions
      : allThemes?.find((cTheme) => cTheme.id === themeId)?.questions;
  }, [isBlitzStep, selectedOrderTheme, themeIndex]);

  const isPlayersScoreChecking = useMemo(
    () =>
      isQuestionSent &&
      isAnswerSent &&
      (themeIndex === 2 || themeIndex === 5 || isBlitzStep) &&
      !isTableAvailable &&
      Number(questionIndex) + 1 === (allQuestions?.length || questionsLength),
    [themeIndex, isTableAvailable, isBlitzStep, questionIndex]
  );

  useEffect(() => {
    if (isPlayersScoreChecking) {
      socket.emit(SocketEmitEvent.moderator_showResultsCalculating, {
        data: { isPlayersScoreChecking: true },
      });
    }
  }, [isPlayersScoreChecking]);

  const handleSend = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_showResultTable, {
      data: {
        isTableAvailable: true,
      },
    });
  }, []);

  if (typeof localThemeIndex !== 'undefined') {
    return (
      <AnswersArray
        themeIndex={localThemeIndex}
        setThemeIndex={setLocalThemeIndex}
      />
    );
  }

  return (
    <Grid
      container
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      alignItems="center"
      className={classes.Grid}
    >
      <div className={classes.ThemeContainer}>
        {teamNameAnswers && Object.keys(teamNameAnswers.themes).length ? (
          <div className={classes.ListItemContainer}>
            {Object.keys(teamNameAnswers.themes).map((key) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <ListItem
                key={key}
                themeIndex={Number(key)}
                setThemeIndex={setLocalThemeIndex}
              />
            ))}
          </div>
        ) : (
          <p className={classes.Text}>Ответы еще не поступили</p>
        )}
        {teamNameAnswers && Object.keys(teamNameAnswers.blitz).length > 0 ? (
          <ListItem themeIndex="blitz" setThemeIndex={setLocalThemeIndex} />
        ) : null}
        {isShowAnswersStep &&
        !isTableAvailable &&
        (themeIndex === 2 || themeIndex === 5 || isBlitzStep) ? (
          <Button
            variant="contained"
            className={classes.Button}
            onClick={handleSend}
          >
            Разрешить показать таблицу
          </Button>
        ) : null}
      </div>
    </Grid>
  );
};

export default observer(CheckAnswers);
