import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'clsx';

import Button from 'components/Button';
import Grid from '@mui/material/Grid';
import Input from 'components/Input';

// Stores
import sessionStore from 'stores/sessionStore';

// Styles
import classes from './PlayerConnection.module.scss';

const PlayerConnection: FC = () => {
  const [sessionInput, setSessionInput] = useState<string>(
    localStorage.getItem('sessionId') || ''
  );

  const navigate = useNavigate();

  const onSessionInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSessionInput(event.target.value);
    },
    []
  );

  const handleNext = useCallback(() => {
    const sessionId = sessionInput.trim();

    sessionStore.setSessionId(sessionId);

    navigate(`/player/${sessionId}`);
  }, [sessionInput]);

  return (
    <Grid
      container
      flexDirection="column"
      height="100%"
      justifyContent="space-around"
      alignItems="center"
      className={cx(classes.Wrapper, 'bg')}
    >
      <div className={classes.Container}>
        <h1>Введите ключ сессии для подключения</h1>
        <div className={classes.Footer}>
          <p className={classes.Label}>введите ключ</p>
          <div>
            <Input
              fullWidth
              inputProps={{
                maxLength: 15,
              }}
              value={sessionInput}
              onChange={onSessionInputChange}
              className={classes.Input}
            />
          </div>
          {/* TODO: check for an existing session Id */}
          <Button
            variant="contained"
            disabled={!sessionInput}
            onClick={handleNext}
            className={classes.Button}
          >
            Подключиться к сеансу
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default PlayerConnection;
