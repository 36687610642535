import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// Styles
import classes from './ListItem.module.scss';

interface IProps {
  themeIndex: number | 'blitz';
  setThemeIndex: Dispatch<SetStateAction<number | 'blitz' | undefined>>;
}

const ListItem: FC<IProps> = ({ themeIndex, setThemeIndex }) => {
  const goToAnswer = useCallback(() => setThemeIndex(themeIndex), [themeIndex]);

  return (
    <Box className={classes.Box}>
      <ListItemButton
        alignItems="flex-start"
        className={classes.ListItemButton}
        onClick={goToAnswer}
      >
        <ListItemText
          primary={themeIndex !== 'blitz' ? `Раунд ${themeIndex + 1}` : 'Блиц'}
          className={classes.Text}
        />
      </ListItemButton>
    </Box>
  );
};

export default ListItem;
