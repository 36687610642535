import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

// Components
import ImageView from 'components/ImageView';
import AudioView from 'components/AudioView';
import VideoView from 'components/VideoView';

// Stores
import sessionStore from 'stores/sessionStore';
import authStore from 'stores/authStore';

// Styles
import classes from './ScreenView.module.scss';

interface IProps {
  isShowQuestionsStep: boolean;
  isModerator?: boolean;
  isPlayer?: boolean;
}

const ScreenView: FC<IProps> = ({
  isShowQuestionsStep,
  isModerator,
  isPlayer,
}) => {
  const {
    themeIndex,
    questionIndex,
    questions,
    isQuestionSent,
    isAnswerSent,
    isPlayersScoreChecking,
    selectedOrderTheme,
    allThemes,
    isBlitzStep,
    blitzTheme,
  } = sessionStore;
  const { isLogin } = authStore;

  const theme = useMemo(() => {
    const themeId = selectedOrderTheme[themeIndex || 0];

    return allThemes?.find((cTheme) => cTheme.id === themeId);
  }, [selectedOrderTheme, themeIndex]);

  const isShownQuestionMedia = useMemo(() => {
    if (isBlitzStep) {
      return (
        !blitzTheme?.questions[questionIndex || 0]?.answerImage &&
        !blitzTheme?.questions[questionIndex || 0]?.answerSound &&
        !blitzTheme?.questions[questionIndex || 0]?.answerYoutubeId
      );
    }

    return (
      !questions[questionIndex || 0]?.answerImage &&
      !questions[questionIndex || 0]?.answerSound &&
      !questions[questionIndex || 0]?.answerYoutubeId
    );
  }, [isBlitzStep, blitzTheme, questions, questionIndex]);

  const isShownAnswerMedia = useMemo(() => {
    if (isBlitzStep) {
      return (
        isAnswerSent &&
        (blitzTheme?.questions[questionIndex || 0]?.answerImage ||
          blitzTheme?.questions[questionIndex || 0]?.answerSound ||
          blitzTheme?.questions[questionIndex || 0]?.answerYoutubeId)
      );
    }

    return (
      isAnswerSent &&
      (questions[questionIndex || 0]?.answerImage ||
        questions[questionIndex || 0]?.answerSound ||
        questions[questionIndex || 0]?.answerYoutubeId)
    );
  }, [isBlitzStep, isAnswerSent, questions, questionIndex, blitzTheme]);

  const isTextOnly = useMemo(() => {
    if (isBlitzStep) {
      return isShownQuestionMedia
        ? !blitzTheme?.questions[questionIndex || 0]?.image &&
            (isLogin
              ? !blitzTheme?.questions[questionIndex || 0]?.sound
              : true) &&
            (isLogin
              ? !blitzTheme?.questions[questionIndex || 0]?.youtubeId
              : true)
        : !blitzTheme?.questions[questionIndex || 0]?.answerImage &&
            (isLogin
              ? !blitzTheme?.questions[questionIndex || 0]?.answerSound
              : true) &&
            (isLogin
              ? !blitzTheme?.questions[questionIndex || 0]?.answerYoutubeId
              : true);
    }

    return isShownQuestionMedia
      ? !questions[questionIndex || 0]?.image &&
          (isLogin ? !questions[questionIndex || 0]?.sound : true) &&
          (isLogin ? !questions[questionIndex || 0]?.youtubeId : true)
      : !questions[questionIndex || 0]?.answerImage &&
          (isLogin ? !questions[questionIndex || 0]?.answerSound : true) &&
          (isLogin ? !questions[questionIndex || 0]?.answerYoutubeId : true);
  }, [questions, questionIndex, isBlitzStep, isShownQuestionMedia]);

  if (isPlayersScoreChecking) {
    return <p className={classes.Text}>Подсчитываются результаты</p>;
  }

  if (
    !(
      !isModerator &&
      isQuestionSent &&
      isAnswerSent &&
      isPlayersScoreChecking
    ) &&
    (isQuestionSent || (isModerator && isShowQuestionsStep))
  ) {
    if (isBlitzStep) {
      return (
        <>
          <h1>БЛИЦ</h1>
          <div
            className={cx(classes.Text, {
              [classes.OnlyImage]: !!questions[questionIndex || 0]?.image,
              [classes.OnlyText]: isTextOnly,
            })}
          >
            <p
              className={cx(classes.QuestionTitle, classes.QuestionTitleBlitz)}
            >
              вопрос {(questionIndex || 0) + 1}
            </p>
            {questions[questionIndex || 0]?.shortText || null}
            {questions[questionIndex || 0]?.image ? (
              <ImageView
                roundIndex={themeIndex}
                questionIndex={questionIndex}
                imageUrl={questions[questionIndex || 0]?.image}
                isModerator={isModerator}
                isViewer={!isModerator && !isPlayer}
              />
            ) : null}
          </div>

          {isShownQuestionMedia ? (
            <>
              {blitzTheme?.questions[questionIndex || 0]?.sound &&
              !isPlayer &&
              isLogin ? (
                <AudioView
                  url={blitzTheme?.questions[questionIndex || 0]?.sound}
                  isModerator={isModerator}
                />
              ) : null}
              {blitzTheme?.questions[questionIndex || 0]?.youtubeId &&
              !isPlayer &&
              isLogin ? (
                <VideoView
                  youtubeId={
                    blitzTheme?.questions[questionIndex || 0].youtubeId
                  }
                  isModerator={isModerator}
                />
              ) : null}
            </>
          ) : null}

          {isShownAnswerMedia ? (
            <>
              {blitzTheme?.questions[questionIndex || 0]?.answerImage ? (
                <ImageView
                  roundIndex={-1}
                  questionIndex={questionIndex}
                  imageUrl={
                    blitzTheme?.questions[questionIndex || 0]?.answerImage
                  }
                  isModerator={isModerator}
                  isViewer={!isModerator && !isPlayer}
                />
              ) : null}
              {blitzTheme?.questions[questionIndex || 0]?.answerSound &&
              !isPlayer &&
              isLogin ? (
                <AudioView
                  url={blitzTheme?.questions[questionIndex || 0]?.answerSound}
                  isModerator={isModerator}
                />
              ) : null}
              {blitzTheme?.questions[questionIndex || 0]?.answerYoutubeId &&
              !isPlayer &&
              isLogin ? (
                <VideoView
                  youtubeId={
                    blitzTheme?.questions[questionIndex || 0].answerYoutubeId
                  }
                  isModerator={isModerator}
                />
              ) : null}
            </>
          ) : null}
          {isAnswerSent ? (
            <p className={classes.Answer}>
              Ответ:{' '}
              <span className={classes.Bold}>
                {isQuestionSent || isModerator
                  ? blitzTheme?.questions[questionIndex || 0]?.answer
                  : null}
              </span>
            </p>
          ) : null}
        </>
      );
    }

    return (
      <>
        <h1>
          РАУНД {(themeIndex || 0) + 1}: {theme ? theme.title : null}
        </h1>
        <div
          className={cx(classes.Text, {
            [classes.OnlyImage]: !!questions[questionIndex || 0]?.image,
            [classes.OnlyText]: isTextOnly,
          })}
        >
          <p className={classes.QuestionTitle}>
            вопрос {(questionIndex || 0) + 1}
          </p>
          {questions[questionIndex || 0]?.shortText || null}
          {questions[questionIndex || 0]?.image ? (
            <ImageView
              roundIndex={themeIndex}
              questionIndex={questionIndex}
              imageUrl={questions[questionIndex || 0]?.image}
              isModerator={isModerator}
              isViewer={!isModerator && !isPlayer}
            />
          ) : null}
        </div>
        {isShownQuestionMedia ? (
          <>
            {questions[questionIndex || 0]?.sound && !isPlayer && isLogin ? (
              <AudioView
                url={questions[questionIndex || 0].sound}
                isModerator={isModerator}
              />
            ) : null}
            {questions[questionIndex || 0]?.youtubeId &&
            !isPlayer &&
            isLogin ? (
              <VideoView
                youtubeId={questions[questionIndex || 0].youtubeId}
                isModerator={isModerator}
              />
            ) : null}
          </>
        ) : null}

        {isShownAnswerMedia ? (
          <>
            {questions[questionIndex || 0]?.answerImage ? (
              <ImageView
                roundIndex={-1}
                questionIndex={questionIndex}
                imageUrl={questions[questionIndex || 0].answerImage}
                isModerator={isModerator}
                isViewer={!isModerator && !isPlayer}
              />
            ) : null}
            {questions[questionIndex || 0]?.answerSound &&
            !isPlayer &&
            isLogin ? (
              <AudioView
                url={questions[questionIndex || 0].answerSound}
                isModerator={isModerator}
              />
            ) : null}
            {questions[questionIndex || 0]?.answerYoutubeId &&
            !isPlayer &&
            isLogin ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <VideoView
                youtubeId={questions[questionIndex || 0].answerYoutubeId}
                isModerator={isModerator}
              />
            ) : null}
          </>
        ) : null}

        {isAnswerSent ? (
          <p className={classes.Answer}>
            Ответ:{' '}
            <span className={classes.Bold}>
              {isQuestionSent || isModerator
                ? questions[questionIndex || 0]?.answer
                : null}
            </span>
          </p>
        ) : null}
      </>
    );
  }

  if (!isQuestionSent) {
    if (isModerator) {
      if (questionIndex === 0 && !isShowQuestionsStep) {
        return <p className={classes.Text}>Показать ответы</p>;
      }
    } else if (typeof questionIndex === 'number' && questionIndex === 0) {
      return <p className={classes.Text}>Ответы</p>;
    }
    if (typeof questionIndex === 'number' && questionIndex > 0) {
      return <p className={classes.Text}>Ожидайте следующего ответа</p>;
    }
  }

  return null;
};

export default observer(ScreenView);
