import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import ReactPlayer from 'react-player';
import clsx from 'clsx';

import Button from 'components/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';
import authStore from 'stores/authStore';

// Components
import AudioView from 'components/AudioView';
import ImageView from 'components/ImageView';

// Types
import { IQuestion } from 'types/question';
import { PlayerAnswer } from 'types/team';
import { SocketEmitEvent } from 'types/socket';

// Styles
import classes from './QuestionItem.module.scss';

interface IProps {
  question: IQuestion;
  index: number;
  isAnswerSaved: boolean;
  setIsAnswerSaved: Dispatch<SetStateAction<boolean>>;
  isViewer?: boolean;
}

const QuestionItem: FC<IProps> = ({
  question,
  index,
  isAnswerSaved,
  setIsAnswerSaved,
  isViewer,
}) => {
  const { socket } = useContext(SocketContext);

  const {
    teamName,
    teamAnswers,
    themeIndex,
    questionIndex,
    isBlitzStep,
    isQuestionsEnded,
    selectedOrderTheme,
    allThemes,
    isRecheckStep,
  } = sessionStore;
  const { isLogin } = authStore;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const currentAnswer = useMemo((): PlayerAnswer | null => {
    if (!teamAnswers) {
      return null;
    }

    if (isBlitzStep) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        teamAnswers.blitz.answers.find(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (teamAnswer) => teamAnswer.questionId === question.id
        ) || null
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (typeof themeIndex === 'undefined' || !teamAnswers.themes[themeIndex]) {
      return null;
    }

    return (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      teamAnswers.themes[themeIndex].answers.find(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (teamAnswer) => teamAnswer.questionId === question.id
      ) || null
    );
  }, [teamAnswers, isBlitzStep, themeIndex]);

  const [checkedOption, setCheckedOption] = useState<string | null>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    currentAnswer?.answer || null
  );
  const [playerAnswer, setPlayerAnswer] = useState<string | null>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    currentAnswer?.answer || null
  );

  const theme = useMemo(() => {
    const themeId = selectedOrderTheme[themeIndex || 0];

    return allThemes?.find((cTheme) => cTheme.id === themeId);
  }, [selectedOrderTheme, themeIndex]);

  const handleCheckboxClick = useCallback(
    (option: string) => {
      setCheckedOption(option);
      setPlayerAnswer(option);

      if (isAnswerSaved) {
        setIsAnswerSaved(false);
      }
    },
    [isAnswerSaved]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPlayerAnswer(event.target.value);

      if (isAnswerSaved) {
        setIsAnswerSaved(false);
      }
    },
    [playerAnswer, isAnswerSaved]
  );

  const handleAnswerSave = useCallback(() => {
    if (!playerAnswer) {
      return;
    }

    setIsAnswerSaved(Boolean(playerAnswer));

    socket.emit(SocketEmitEvent.player_setTeamAnswer, {
      data: {
        teamName,
        themeIndex,
        questionIndex: index,
        answer: playerAnswer,
      },
    });
  }, [question, themeIndex, teamName, questionIndex, playerAnswer]);

  return (
    <>
      <div className={classes.Titles}>
        {isQuestionsEnded ? (
          <h2 className={classes.Animation}>Перепроверка ответов</h2>
        ) : null}
        {isBlitzStep ? (
          <h1>БЛИЦ</h1>
        ) : (
          <h1>
            РАУНД {(themeIndex || 0) + 1}: {theme ? theme.title : null}
          </h1>
        )}
      </div>
      {question.text ? (
        <div
          className={clsx(classes.Text, {
            [classes.OnlyImage]: !!question.image,
            [classes.OnlyText]:
              !question.image &&
              !question.options &&
              (isLogin ? !question.sound : true) &&
              (isLogin ? !question.youtubeId : true),
          })}
        >
          <p className={classes.QuestionTitle}>вопрос {index + 1}</p>
          {question.text}
          {question.image ? (
            <ImageView
              roundIndex={themeIndex}
              questionIndex={index}
              imageUrl={question.image}
              isViewer={isViewer}
            />
          ) : null}
        </div>
      ) : null}
      {question.sound && isViewer && isLogin ? (
        <AudioView url={question.sound} isUncontrollable />
      ) : null}
      {question.youtubeId && isViewer && isLogin ? (
        <ReactPlayer
          className={clsx(classes.Media, classes.Video)}
          playing={false}
          light={isRecheckStep}
          controls
          url={`https://www.youtube.com/watch?v=${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            question.youtubeId
          }`}
        />
      ) : null}
      {question.options ? (
        <div className={classes.Options}>
          <p>Варианты ответа: </p>
          <div className={classes.Grid}>
            {question.options.map((option, cIndex) => (
              <div className={classes.Option} key={option}>
                {isViewer ? (
                  <p>
                    {cIndex + 1}. {option}
                  </p>
                ) : (
                  <>
                    <Checkbox
                      checked={checkedOption === option}
                      onClick={() => handleCheckboxClick(option)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <p>{option}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {!isViewer ? (
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          gap="10px"
          alignItems="center"
          className={classes.AnswerSection}
        >
          {question.options ? (
            <Button
              variant="contained"
              className={classes.ButtonChecked}
              onClick={handleAnswerSave}
            >
              <CheckIcon className={classes.Icon} />
            </Button>
          ) : (
            <div className={classes.TextFieldWrapper}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={playerAnswer || ''}
                onChange={handleChange}
                placeholder="введите ответ"
                className={classes.TextField}
              />
              <Button
                variant="contained"
                className={classes.ButtonChecked}
                disabled={!playerAnswer?.length}
                onClick={handleAnswerSave}
              >
                <CheckIcon className={classes.Icon} />
              </Button>
            </div>
          )}
        </Grid>
      ) : null}
    </>
  );
};

export default observer(QuestionItem);
