import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Modal from 'components/Modal';
import Box from '@mui/material/Box';
import Button from 'components/Button';

import PersonIcon from 'components/PersonIcon';
import LogoutIcon from 'components/LogoutIcon';

// Components
import Results from 'components/Results';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Hooks
import { useRealtime } from 'hooks/useRealtime';
import { useSocket } from 'hooks/useSocket';

// Stores
import authStore from 'stores/authStore';
import sessionStore from 'stores/sessionStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Components
import CheckAnswers from './CheckAnswers';
import Game from './Game';
import Questions from './Questions';

// Styles
import classes from './Moderator.module.scss';

const Moderator: FC = () => {
  const { setSocket } = useContext(SocketContext);

  const [tab, setTab] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDisconnectedTeamModalOpen, setDisconnectedTeamModalOpen] =
    useState(false);
  const [questionsThemeIndex, setQuestionsThemeIndex] = useState<
    number | 'blitz' | undefined
  >(undefined);
  const [answersThemeIndex, setAnswersThemeIndex] = useState<
    number | 'blitz' | undefined
  >(undefined);

  const socket = useSocket();
  const navigate = useNavigate();
  useRealtime(socket);

  const { accessToken } = authStore;
  const { teamNames, sessionId, gameId, disconnectedTeam } = sessionStore;

  useEffect(() => {
    setSocket(socket);
  }, [socket]);

  useEffect(() => {
    const curSessionId = sessionId || localStorage.getItem('sessionId');

    if (!curSessionId) {
      return;
    }

    if (!socket.isConnected) {
      socket.connect({
        ...(curSessionId ? { sessionId: curSessionId } : null),
        ...(gameId ? { gameId } : null),
        token: accessToken as string,
      });
    }
  }, [sessionId]);

  useEffect(() => {
    const curSessionId = sessionId || localStorage.getItem('sessionId');

    if (curSessionId) {
      return;
    }

    socket.connect(
      {
        ...(curSessionId ? { sessionId: curSessionId } : null),
        ...(gameId ? { gameId } : null),
        token: accessToken as string,
      },
      {
        reconnection: false,
      }
    );
  }, []);

  useEffect(() => {
    if (socket.isConnected) {
      socket.emit(SocketEmitEvent.player_localDate, {
        data: { localDate: new Date().toISOString() },
      });
    }
  }, [socket.isConnected]);

  const resetThemeIndex = useCallback(() => {
    setQuestionsThemeIndex(undefined);
    setAnswersThemeIndex(undefined);
  }, []);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setTab(newValue);
    },
    []
  );

  const disconnectedTeamCount = useMemo(() => {
    return disconnectedTeam.filter(
      (team) => team.currentStatus === 'disconnected'
    );
  }, [disconnectedTeam]);

  const handleOpen = useCallback(() => setModalOpen(true), []);
  const handleClose = useCallback(() => setModalOpen(false), []);

  const handleDisconnectedTeamModalOpen = useCallback(
    () => setDisconnectedTeamModalOpen(true),
    []
  );
  const handleDisconnectedTeamModalClose = useCallback(
    () => setDisconnectedTeamModalOpen(false),
    []
  );

  const handleLogout = useCallback(() => {
    authStore.logout();

    navigate('/login', {
      replace: true,
    });

    socket.disconnect();
    handleClose();
  }, [socket]);
  const handleSessionLeft = useCallback(() => {
    sessionStore.clearGame();

    navigate('/', {
      replace: true,
    });

    socket.disconnect();
    handleClose();
  }, [socket]);

  const getTabContentByIndex = useCallback(
    (cTab: string | null): ReactElement => {
      switch (cTab) {
        case 'results':
          return <Results isModeratorTab />;
        case 'answers':
          return (
            <CheckAnswers
              themeIndex={answersThemeIndex}
              setThemeIndex={setAnswersThemeIndex}
            />
          );
        case 'questions':
          return (
            <Questions
              themeIndex={questionsThemeIndex}
              setThemeIndex={setQuestionsThemeIndex}
            />
          );
        default:
          return <Game />;
      }
    },
    [questionsThemeIndex, answersThemeIndex]
  );

  return (
    <span className={cx(classes.Wrapper, 'bg')}>
      <div className={classes.Container}>
        <div className={classes.HeaderContainer}>
          <div className={classes.LogoutGame} onClick={handleOpen}>
            <LogoutIcon />
          </div>
          <h5>Игра {sessionId}</h5>
          <div
            className={classes.ParticipantsCount}
            onClick={handleDisconnectedTeamModalOpen}
          >
            <PersonIcon />
            <p className={classes.Disconnected}>
              {disconnectedTeamCount.length}/{teamNames.length}
            </p>
          </div>
        </div>
        {getTabContentByIndex(tab)}
        <Tabs
          value={tab}
          onChange={handleTabChange}
          onClick={resetThemeIndex}
          variant="fullWidth"
          className={classes.Tabs}
        >
          <Tab label="Игра" value={null} />
          <Tab label="Вопросы" value="questions" />
          <Tab label="Ответы" value="answers" />
          <Tab label="Таблица" value="results" />
        </Tabs>
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.Modal}>
            <div className={classes.Buttons}>
              <Button onClick={handleLogout}>Выйти из профиля</Button>
              <Button onClick={handleSessionLeft}>Выйти из сессии</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={isDisconnectedTeamModalOpen}
          onClose={handleDisconnectedTeamModalClose}
        >
          <Box className={classes.Modal}>
            <p>Вышедшие команды:</p>
            <div className={classes.Line} />
            {disconnectedTeamCount.length > 0 ? (
              disconnectedTeamCount.map((team, index) => (
                <p key={team.teamName} className={classes.DisconnectedTeam}>
                  {index + 1}. {team.teamName}
                </p>
              ))
            ) : (
              <p className={classes.DisconnectedTeam}>Нет команд</p>
            )}
          </Box>
        </Modal>
      </div>
    </span>
  );
};

export default observer(Moderator);
