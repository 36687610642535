import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'components/Button';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { PlayerAnswer } from 'types/team';

// Styles
import classes from './AnswerCard.module.scss';

interface IProps {
  data: PlayerAnswer;
  teamName: string;
  rightAnswer: string | undefined;
  questionIndex: number;
  themeIndex: number | 'blitz';
  setPoints: (value: number | ((prevVal: number) => number)) => void;
  points: number;
}

const AnswerCard: FC<IProps> = ({
  data,
  teamName,
  rightAnswer,
  themeIndex,
  questionIndex,
  setPoints,
  points,
}) => {
  const [isRightAnswer, setRightAnswer] = useState<boolean | null>(
    typeof data.isAnswerRight !== 'undefined' ? data.isAnswerRight : false
  );

  const { isAnswerDataUpdated } = sessionStore;

  const handleRightAnswer = useCallback(() => {
    if (typeof data.isAnswerRight === 'undefined' || !data.isAnswerRight) {
      setPoints((prevPoints) => prevPoints + 1);
    }

    if (!isAnswerDataUpdated) {
      sessionStore.setIsAnswerDataUpdated(true);
    }

    setRightAnswer(true);

    sessionStore.setCorrectAnswer(
      teamName,
      data.questionId,
      true,
      themeIndex === 'blitz',
      points + 1,
      themeIndex !== 'blitz' ? Number(themeIndex) : undefined
    );
  }, [isRightAnswer, isAnswerDataUpdated, points, setPoints]);

  const handleWrongAnswer = useCallback(() => {
    if (data.isAnswerRight) {
      setPoints((prevPoints) => prevPoints - 1);
    }

    if (!isAnswerDataUpdated) {
      sessionStore.setIsAnswerDataUpdated(true);
    }

    setRightAnswer(false);

    sessionStore.setCorrectAnswer(
      teamName,
      data.questionId,
      false,
      themeIndex === 'blitz',
      points - 1,
      themeIndex !== 'blitz' ? Number(themeIndex) : undefined
    );
  }, [isRightAnswer, isAnswerDataUpdated, points]);

  return (
    <div className={classes.Box}>
      <div className={classes.Title}>
        <p>Вопрос {questionIndex + 1}</p>
      </div>
      <div className={classes.Content}>
        <p>Ответ: {data.answer}</p>
        <Button
          onClick={handleRightAnswer}
          disabled={isRightAnswer === true}
          variant="outlined"
          className={classes.Button}
        >
          Верно
        </Button>
        <p>Правильный ответ: {rightAnswer}</p>
        <Button
          onClick={handleWrongAnswer}
          disabled={isRightAnswer === false}
          variant="outlined"
          className={classes.Button}
        >
          Неверно
        </Button>
      </div>
    </div>
  );
};

export default observer(AnswerCard);
