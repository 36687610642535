import React, { FC } from 'react';
import { Navigate, Route, Routes as CRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// Components
import Loading from 'components/Loading';
import Question from 'components/Question';

// Layouts
import PlayerLayout from 'layouts/PlayerLayout';
import ViewerLayout from 'layouts/ViewerLayout';
import AdminLayout from 'layouts/AdminLayout';
import PublicLayout from 'layouts/PublicLayout';

// Routes
import Login from 'routes/Login';
import Player from 'routes/Player';
import Home from 'routes/Home';
import PlayerConnection from 'routes/Player/PlayerConnection';
import Viewer from 'routes/Viewer';
import Admin from 'routes/Admin';
import Moderator from 'routes/Moderator';
import Connection from 'routes/Admin/Connection';
import SelectGame from 'routes/Admin/SelectGame';

// Stores
import authStore from 'stores/authStore';
import sessionStore from 'stores/sessionStore';

const Routes: FC = () => {
  const { isLogin, isChecking } = authStore;
  const { sessionId, gameId, isLoading } = sessionStore;

  if (isChecking || isLoading) {
    return <Loading />;
  }

  return (
    <CRoutes>
      <Route path="/" element={<PublicLayout />}>
        <Route path="pre-show/:questionId" element={<Question />} />
      </Route>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLogin ? (
        <>
          {!sessionId && !gameId ? (
            <Route path="/" element={<AdminLayout />}>
              <Route index element={<Admin />} />
              <Route path="connection" element={<Connection />} />
              <Route path="new-game" element={<SelectGame />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          ) : (
            <Route path="/">
              <Route index element={<Moderator />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          )}
          <Route path="/" element={<ViewerLayout />}>
            <Route path="viewer" element={<Viewer />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </>
      ) : (
        <>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="viewer/login" element={<Login isViewer />} />
          </Route>
          <Route path="/" element={<PlayerLayout />}>
            <Route path="player/:sessionId" element={<Player />} />
            <Route path="player" element={<PlayerConnection />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
          <Route path="/" element={<ViewerLayout />}>
            <Route path="viewer" element={<Viewer />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </>
      )}
    </CRoutes>
  );
};

export default observer(Routes);
