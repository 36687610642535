import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import cx from 'clsx';

import Button from 'components/Button';
import Input from 'components/Input';
import Helmet from 'react-helmet';

// Types
import { SocketCtx } from 'types/socket';

// Styles
import classes from './SetSessionKey.module.scss';

interface SessionProps {
  socket: SocketCtx;
  setSessionId: Dispatch<SetStateAction<string>>;
}

const SetSessionKey: FC<SessionProps> = ({ socket, setSessionId }) => {
  const [sessionInput, setSessionInput] = useState<string>(
    localStorage.getItem('sessionId') || ''
  );

  const onSessionInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSessionInput(event.target.value);
    },
    []
  );

  const handleNext = useCallback(() => {
    const currentSessionId = sessionInput.trim();

    if (!currentSessionId) {
      return;
    }

    setSessionId(currentSessionId);

    socket?.connect({
      sessionId: currentSessionId,
    });
  }, [sessionInput, socket]);

  return (
    <div className={cx(classes.Wrapper, 'bg')}>
      <Helmet title="Вход для зрителя" />
      <div className={classes.Container}>
        <h1>Введите ключ вашей игры</h1>
        <div className={classes.Footer}>
          <p className={classes.Label}>введите ключ</p>
          <div>
            <Input
              fullWidth
              value={sessionInput}
              onChange={onSessionInputChange}
              className={classes.Input}
              inputProps={{
                maxLength: 15,
              }}
            />
          </div>
          <Button
            variant="contained"
            onClick={handleNext}
            className={classes.Button}
          >
            Подключиться к сеансу
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SetSessionKey;
