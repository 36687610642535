import { ManagerOptions, SocketOptions } from 'socket.io-client';

export enum SocketHandlerEvent {
  game_updatedData = 'game_updatedData',
  game_notification = 'game_notification',
}

export enum SocketEmitEvent {
  moderator_addNewTeam = 'moderator.addNewTeam',
  moderator_back = 'moderator.back',
  moderator_blitzTimer = 'moderator.blitzTimer',
  moderator_playMedia = 'moderator.playMedia',
  moderator_removeManualTeam = 'moderator.removeManualTeam',
  moderator_startGame = 'moderator.startGame',
  moderator_selectThemes = 'moderator.selectThemes',
  moderator_saveResults = 'moderator.saveResults',
  moderator_sendQuestion = 'moderator.sendQuestion',
  moderator_showAnswers = 'moderator.showAnswers',
  moderator_sendBlitz = 'moderator.sendBlitz',
  moderator_showQuestion = 'moderator.showQuestion',
  moderator_showResultsCalculating = 'moderator.showResultsCalculating',
  moderator_showResultTable = 'moderator.showResultTable',
  moderator_showTeamCountResult = 'moderator.showTeamCountResult',
  moderator_showBlitz = 'moderator.showBlitz',
  moderator_timer = 'moderator.timer',
  moderator_updateTeamResults = 'moderator.updateTeamResults',
  player_leftPage = 'player.leftPage',
  player_localDate = 'player.localDate',
  player_setTeamAnswer = 'player.setTeamAnswer',
}

export type SubscribeFn = (
  eventName: SocketHandlerEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb: (...data: any[]) => void
) => void;

export type UnsubscribeFn = (
  eventName: SocketHandlerEvent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb?: (...data: any[]) => void
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EmitFn = (eventName: SocketEmitEvent, ...data: any[]) => void;

export enum SocketQueryField {
  teamName = 'teamName',
  token = 'token',
  sessionId = 'sessionId',
}

export interface SocketCtx {
  connect: (
    query: { [key in SocketQueryField]?: string },
    options?: Partial<ManagerOptions & SocketOptions>
  ) => void;
  disconnect: () => void;
  subscribe: SubscribeFn;
  unsubscribe: UnsubscribeFn;
  emit: EmitFn;
  isInit: boolean;
  isConnected: boolean;
}
