import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react-lite';

// Components
import Button from 'components/Button';
import Participants from 'components/Participants';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Styles
import classes from './StartGame.module.scss';

const StartGame = () => {
  const { teamNames, sessionId } = sessionStore;

  const { socket } = useContext(SocketContext);

  const handleStart = useCallback(() => {
    if (!socket) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_startGame, {
      data: { isStarted: true },
    });
  }, [socket]);

  return (
    <div className={classes.Wrapper}>
      {teamNames.length ? (
        <>
          <h1>Участники</h1>
          <div className={classes.Container}>
            <Participants teamNames={teamNames} />
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={!teamNames.length}
            onClick={handleStart}
            className={classes.Button}
          >
            Начать
          </Button>
        </>
      ) : (
        <div className={classes.Container}>
          <h1>Игра началась</h1>
          <br />
          <h2>
            Пожалуйста, используйте Session ID
            <br />
            для подключения
          </h2>
          <div className={classes.Key}>
            <p className={classes.KeyTitle}>введите ключ</p>
            {sessionId}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(StartGame);
