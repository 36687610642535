import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

// Stores
import sessionStore from 'stores/sessionStore';

// Components
import PlayerContent from './PlayerContent';

// Styles
import classes from './Player.module.scss';

const Player: FC = () => {
  const { sessionId } = useParams();

  useEffect(() => {
    localStorage.removeItem('sessionId');

    sessionStore.setSessionId(sessionId);
  }, []);

  return (
    <div className={classes.Container}>
      <Grid
        container
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center"
        flexWrap="nowrap"
      >
        <PlayerContent />
      </Grid>
    </div>
  );
};

export default observer(Player);
