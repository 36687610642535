// Helpers
import axios from 'api/helpers/axios';

// Types
import { IQuestion } from 'types/question';

export default {
  async get(questionId: string): Promise<IQuestion> {
    const response = await axios.get(`/questions/${questionId}`);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    return response.data.data;
  },
};
