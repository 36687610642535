import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import QRCode from 'react-qr-code';
import cx from 'clsx';

// Components
import Participants from 'components/Participants';

// Stores
import sessionStore from 'stores/sessionStore';

import classes from './TeamConnection.module.scss';

interface IProps {
  isViewer?: boolean;
}

const TeamConnection: FC<IProps> = ({ isViewer = false }) => {
  const { sessionId, teamNames } = sessionStore;

  return (
    <div className={cx(classes.Wrapper, 'bg')}>
      <h1>Участники</h1>
      {sessionId && isViewer ? (
        <div className={classes.QRCode}>
          <QRCode
            value={`${process.env.REACT_APP_HOST || ''}/player/${sessionId}`}
            viewBox="0 0 256 256"
          />
        </div>
      ) : null}
      <Participants teamNames={teamNames} />
    </div>
  );
};

export default observer(TeamConnection);
