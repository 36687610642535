import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import Button from 'components/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Components
import Timer from 'components/Timer';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { IQuestion } from 'types/question';

// Components
import QuestionItem from './QuestionItem';

// Styles
import classes from './QuestionsArray.module.scss';

interface IProps {
  questions: IQuestion[];
  isViewer?: boolean;
}

const QuestionsArray: FC<IProps> = ({ questions, isViewer }) => {
  const {
    questionIndex,
    timerEndTime,
    timerPausedTime,
    isTimerFinished,
    isQuestionsEnded,
    isRecheckStep,
    isQuestionSent,
    isBlitzStep,
    blitzTheme,
    game,
  } = sessionStore;

  const [isAnswerSaved, setAnswerSaved] = useState<boolean>(false);

  const timer = useMemo(() => {
    if (game?.questionTimer) {
      return game.questionTimer;
    }

    if (typeof questionIndex !== 'number') {
      return 60;
    }

    if (isBlitzStep && isQuestionSent) {
      return blitzTheme?.questions[questionIndex].timer;
    }

    return questions[questionIndex] ? questions[questionIndex].timer : 60;
  }, [questionIndex, questions, isBlitzStep, isQuestionSent]);

  const recheckTimer = useMemo(() => {
    if (isBlitzStep) {
      return game?.blitzTimerCount ? game.blitzTimerCount : 60;
    }

    return game?.timerCount ? game.timerCount : 60;
  }, [isBlitzStep]);

  const [localQuestionIndex, setLocalQuestionIndex] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(
    isRecheckStep ? recheckTimer : timer || 60
  );

  const nextQuestion = useCallback(() => {
    if ((questionIndex || 0) <= localQuestionIndex) {
      return;
    }

    setAnswerSaved(false);
    setLocalQuestionIndex((qIndex) => qIndex + 1);
  }, [questionIndex, localQuestionIndex]);

  const prevQuestion = useCallback(() => {
    if (localQuestionIndex <= 0) {
      return;
    }

    setAnswerSaved(false);
    setLocalQuestionIndex((qIndex) => qIndex - 1);
  }, [localQuestionIndex]);

  const jumpToQuestion = useCallback(() => {
    if (typeof questionIndex !== 'number') {
      return;
    }

    setAnswerSaved(false);
    setLocalQuestionIndex(isQuestionSent ? questionIndex : questionIndex - 1);
  }, [questionIndex, isQuestionSent]);

  useEffect(() => {
    if (isRecheckStep && isViewer && questions) {
      if (localQuestionIndex + 1 === questions.length) {
        return;
      }

      const module = Math.round(recheckTimer / questions.length);

      if (seconds !== recheckTimer && seconds % module === 0) {
        setLocalQuestionIndex((qIndex) => qIndex + 1);
      }
    }
  }, [seconds]);

  return (
    <div className={cx(classes.Wrapper, 'bg')}>
      <div className={classes.Container}>
        {questions.map((question, index) => (
          <div
            className={classes.Slide}
            key={question.id}
            style={{
              transform: `translateX(-${localQuestionIndex * 100}%)`,
            }}
          >
            <QuestionItem
              question={question}
              index={index}
              isAnswerSaved={isAnswerSaved}
              setIsAnswerSaved={setAnswerSaved}
              isViewer={isViewer}
            />
          </div>
        ))}
        <div className={classes.FooterWrapper}>
          {isAnswerSaved ? (
            <p className={classes.SavedAnswer}>Ваш ответ сохранен</p>
          ) : null}
          {/* eslint-disable-next-line no-nested-ternary */}
          {!isViewer &&
          questions.length !== localQuestionIndex + 1 &&
          !isQuestionsEnded ? (
            <div className={classes.ButtonJumpWrapper}>
              <Button
                variant="outlined"
                className={classes.ButtonJump}
                onClick={jumpToQuestion}
              >
                К текущему вопросу
              </Button>
            </div>
          ) : (isQuestionSent || isRecheckStep) && !isTimerFinished ? (
            <div className={classes.Timer}>
              <Timer
                seconds={isRecheckStep ? recheckTimer : timer || 60}
                timerEndTime={timerEndTime}
                timerPausedTime={timerPausedTime}
                isTimerFinished={isTimerFinished}
                setSeconds={setSeconds}
              />
            </div>
          ) : null}
        </div>
      </div>
      {!isViewer && localQuestionIndex !== 0 ? (
        <Button
          variant="contained"
          className={classes.BackButton}
          onClick={prevQuestion}
        >
          <ArrowBackIcon className={classes.IconArrow} />
          <span className={classes.DirectionButtonText}>Пред вопрос</span>
        </Button>
      ) : null}
      {!isViewer && localQuestionIndex + 1 !== questions.length ? (
        <Button
          variant="contained"
          className={classes.ForwardButton}
          onClick={nextQuestion}
        >
          <span className={classes.DirectionButtonText}>След вопрос</span>
          <ArrowForwardIcon className={classes.IconArrow} />
        </Button>
      ) : null}
    </div>
  );
};

export default observer(QuestionsArray);
