import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import cx from 'clsx';

import Button from 'components/Button';
import Input from 'components/Input';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';

// Styles
import classes from './SetTeamName.module.scss';

const SetTeamName: FC = () => {
  const [teamName, setTeamName] = useState<string>(
    localStorage.getItem('teamName') || ''
  );

  const { socket } = useContext(SocketContext);
  const { sessionId } = useParams();

  const onTeamNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTeamName(event.target.value);
    },
    []
  );

  const handleNext = useCallback(
    (cTeamName: string) => {
      const currentTeamName = cTeamName.trim();

      if (!currentTeamName.length || !socket) {
        return;
      }

      sessionStore.setTeamName(currentTeamName);

      if (!sessionId) {
        return;
      }

      socket.connect({
        teamName: currentTeamName,
        sessionId,
      });
    },
    [socket, sessionId]
  );

  return (
    <div className={cx(classes.Container, 'bg')}>
      <div className={classes.FlexContainer}>
        <h1>Введите название вашей команды</h1>
        <div className={classes.Footer}>
          <p className={classes.Label}>введите название</p>
          <div>
            <Input
              inputProps={{
                maxLength: 25,
              }}
              fullWidth
              value={teamName}
              onChange={onTeamNameChange}
              className={classes.Input}
            />
          </div>
          <Button
            variant="contained"
            onClick={() => handleNext(teamName)}
            className={classes.Button}
          >
            Подключиться к сеансу
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SetTeamName;
