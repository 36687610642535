import React, { FC } from 'react';
import { Outlet } from 'react-router';
import { observer } from 'mobx-react-lite';

import Grid from '@mui/material/Grid';

import PersonIcon from 'components/PersonIcon';
import LogoutIcon from 'components/LogoutIcon';

// Styles
import classes from './PublicLayout.module.scss';

const PublicLayout: FC = () => {
  return (
    <span className={classes.Wrapper}>
      <div className={classes.Container}>
        <div className={classes.LogoutGame}>
          <LogoutIcon />
        </div>
        <div className={classes.ParticipantsCount}>
          <PersonIcon />
          <p>0</p>
        </div>
        <div className={classes.PublicContainer}>
          <Grid
            container
            flexDirection="column"
            height="100%"
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            flexWrap="nowrap"
          >
            <Outlet />
          </Grid>
        </div>
      </div>
    </span>
  );
};

export default observer(PublicLayout);
