import React, { MouseEventHandler, ReactNode, useRef } from 'react';

import './react-player-circle-controls.scss';

interface Point {
  x: number;
  y: number;
}

const getCircleCenterCoords = ({
  x,
  y,
  width,
}: {
  x: number;
  y: number;
  width: number;
}): Point => {
  const radius = width / 2;

  return { x: x + radius, y: y + radius };
};

const getRotationForPoint = (vertex: Point, point: Point) => {
  // Probably better way to name and approach these
  // My high school trigonometry knowledge is fuzzy, please help!
  const adjacent = vertex.y - point.y;
  const opposite = point.x - vertex.x;
  const centralAngle = Math.atan(opposite / adjacent);
  const mod = point.y > vertex.y ? Math.PI : 2 * Math.PI;
  const rotation =
    centralAngle + mod > 2 * Math.PI ? centralAngle : centralAngle + mod;

  return rotation / (2 * Math.PI);
};

const getDefaultLabels = (playing?: boolean) => ({
  PLAY_BUTTON: playing ? 'Pause' : 'Play',
});

interface PlayIconProps {
  playing?: boolean;
}

const PlayIcon = ({ playing }: PlayIconProps) => (
  <span className={`rpcc-play-icon${playing ? ' pause' : ''}`} />
);

interface IProps {
  ariaLabels?: {
    PLAY_BUTTON: string;
  } | null;
  icon?: ReactNode;
  loaded?: number;
  played?: number;
  playing?: boolean;
  onSeek?: ((progress: number) => void) | null;
  onTogglePlaying?: () => void;
}

const ReactPlayerCircleControls = ({
  ariaLabels,
  icon,
  loaded = 0,
  played = 0,
  playing,
  onSeek,
  onTogglePlaying,
}: IProps) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const labels = ariaLabels || getDefaultLabels(playing);
  const vars = {
    '--rpcc-progress-loaded': loaded,
    '--rpcc-progress-played': played,
  };

  const onSeekClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (
      !playerRef.current ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (buttonRef.current && buttonRef.current.contains(e.target))
    ) {
      return;
    }

    const point: Point = { x: e.clientX, y: e.clientY };
    const vertex: Point = getCircleCenterCoords(
      playerRef.current.getBoundingClientRect()
    );

    if (typeof onSeek === 'function') {
      onSeek(getRotationForPoint(vertex, point));
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div className="rpcc-player" style={vars} onClick={onSeek && onSeekClick}>
      <div ref={playerRef} className="rpcc-player-inner">
        <svg className="rpcc-ring-container">
          <circle
            shapeRendering="geometricPrecision"
            className="rpcc-ring rpcc-ring__duration"
          />
          <circle
            shapeRendering="geometricPrecision"
            className="rpcc-ring rpcc-ring__loaded"
          />
          <circle
            shapeRendering="geometricPrecision"
            className="rpcc-ring rpcc-ring__played"
          />
        </svg>
        <div className="rpcc-ring-end" />
        <button
          ref={buttonRef}
          type="button"
          className="rpcc-play-button"
          aria-label={labels.PLAY_BUTTON}
          onClick={onTogglePlaying}
        >
          {icon || <PlayIcon playing={playing} />}
        </button>
      </div>
    </div>
  );
};

export default ReactPlayerCircleControls;
