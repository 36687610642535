import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import Modal from 'components/Modal';

// Components
import Loading from 'components/Loading';

// Services
import { getBase64Cache } from 'services/cacheService';

// Types
import { SocketEmitEvent } from 'types/socket';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';

// Styles
import classes from './ImageView.module.scss';

interface IProps {
  roundIndex?: number;
  questionIndex?: number;
  imageUrl?: string;
  isModerator?: boolean;
  isViewer?: boolean;
}

const ImageView: FC<IProps> = ({
  roundIndex,
  questionIndex,
  imageUrl,
  isModerator,
  isViewer,
}) => {
  const [imageBase64, setImageBase64] = useState<string | null | undefined>(
    null
  );
  const [isFullScreenImage, setFullScreenImage] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const { socket } = useContext(SocketContext);

  const { mediaStatus } = sessionStore;

  const handleImage = useCallback(() => {
    setFullScreenImage((isItFull) => !isItFull);

    if (!isModerator || !imageRef.current) {
      return;
    }

    if (isFullScreenImage) {
      socket.emit(SocketEmitEvent.moderator_playMedia, {
        data: { status: 'ended' },
      });
    } else {
      socket.emit(SocketEmitEvent.moderator_playMedia, {
        data: { status: 'played' },
      });
    }
  }, [isFullScreenImage]);

  const isImageHeightBigger = useMemo(() => {
    if (
      typeof imageRef.current?.clientHeight === 'undefined' ||
      typeof imageRef.current?.clientWidth === 'undefined'
    ) {
      return false;
    }

    return imageRef.current.clientHeight > imageRef.current.clientWidth;
  }, [imageRef.current]);

  useEffect(() => {
    if (
      typeof roundIndex !== 'number' ||
      typeof questionIndex !== 'number' ||
      typeof imageUrl !== 'string'
    ) {
      return;
    }

    (async () => {
      await getBase64Cache(roundIndex, questionIndex, imageUrl)?.then(
        (value) => {
          setImageBase64(`data:image/png;base64,${value}`);
        }
      );
    })();
  }, [roundIndex, questionIndex, imageUrl]);

  useEffect(() => {
    if (!isViewer || !imageRef.current) {
      return;
    }

    if (mediaStatus === 'played') {
      setFullScreenImage(true);
    }

    if (mediaStatus === 'ended') {
      setFullScreenImage(false);
    }
  }, [mediaStatus]);

  if (
    !imageBase64 ||
    typeof roundIndex !== 'number' ||
    typeof questionIndex !== 'number' ||
    typeof imageUrl !== 'string'
  ) {
    return <Loading />;
  }

  return (
    <>
      <div className={classes.Media} onClick={handleImage}>
        <img
          className={clsx(classes.Media, classes.Image)}
          ref={imageRef}
          src={imageBase64}
          alt="Question"
        />
      </div>
      <Modal
        open={isFullScreenImage}
        onClose={handleImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleImage}
      >
        <img
          className={clsx(classes.Media, classes.Image, classes.FullImage, {
            [classes.HighImage]: isImageHeightBigger,
          })}
          src={imageBase64}
          alt="Question"
        />
      </Modal>
    </>
  );
};

export default observer(ImageView);
