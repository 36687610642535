import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import Button from 'components/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Stores
import sessionStore from 'stores/sessionStore';

// Styles
import classes from './QuestionCardBlitz.module.scss';

interface QuestionCardBlitzProps {
  resetThemeIndex: () => void;
}

const QuestionCardBlitz: FC<QuestionCardBlitzProps> = ({ resetThemeIndex }) => {
  const { blitzTheme } = sessionStore;

  return (
    <div className={cx(classes.Wrapper, 'bg')}>
      <Button
        variant="contained"
        className={classes.Button}
        onClick={resetThemeIndex}
      >
        <ArrowBackIcon className={classes.IconArrow} />
        Назад
      </Button>
      {blitzTheme
        ? blitzTheme.questions?.map((question, index) => (
            <div key={question.id} className={classes.Container}>
              <h4>Вопрос {index + 1}</h4>
              <p className={classes.Text}>
                <span className={classes.Bold}>Текст:</span> {question.text}
              </p>
              <p>
                <span className={classes.Bold}>Ответ:</span> {question.answer}
              </p>
            </div>
          ))
        : null}
    </div>
  );
};

export default observer(QuestionCardBlitz);
