import React, { FC } from 'react';

import Grid from '@mui/material/Grid';

// Components
import GameContent from './GameContent';

// Styles
import classes from './Game.module.scss';

const Game: FC = () => {
  return (
    <div className={classes.Container}>
      <Grid
        container
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center"
        flexWrap="nowrap"
        className={classes.Grid}
      >
        <GameContent />
      </Grid>
    </div>
  );
};

export default Game;
