import { action, makeObservable, observable, runInAction } from 'mobx';

// Api
import api from 'api';

// Types
import { IQuestion } from 'types/question';

class QuestionStore {
  @observable
  loading: boolean = false;

  @observable
  question: IQuestion | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  getQuestion = async (questionId: string): Promise<void> => {
    try {
      this.loading = true;

      const question = await api.question.get(questionId);

      runInAction(() => {
        this.question = question;
      });
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}

const questionStore = new QuestionStore();

export default questionStore;
