import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

const Button = styled(MuiButton)(() => ({
  paddingLeft: 30,
  paddingRight: 30,
  minWidth: '45vmin',
  backgroundColor: '#E2A41D',
  border: 'none',
  borderRadius: 36,
  fontFamily: 'Montserrat, sans-serif',
  fontSize: 20,
  fontWeight: 600,
  textTransform: 'none',
  color: '#041A30 !important',
  '&:hover': {
    backgroundColor: '#D38B00',
    color: '#041A30',
    border: 'none !important',
  },
  '&:active': {
    backgroundColor: '#E2B000',
    color: '#041A30',
  },
  '&:disabled': {
    backgroundColor: '#A89670',
    color: '#4E565D',
  },
  '.MuiTouchRipple-child': {
    display: 'none',
  },
}));

export default Button;
