import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

// Components
import StartGame from 'components/StartGame';
import Themes from 'components/Themes';
import Question from 'components/Question';
import RecheckAnswers from 'components/RecheckAnswers';
import ShowAnswers from 'components/ShowAnswers';
import Results from 'components/Results';
import BlitzQuestion from 'components/Blitz/BlitzQuestion';
import ShowBlitzAnswers from 'components/Blitz/ShowBlitzAnswers';
import RecheckBlitzAnswers from 'components/Blitz/RecheckBlitzAnswers';

// Stores
import sessionStore from 'stores/sessionStore';

const GameContent: FC = () => {
  const {
    isStarted,
    isThemesSelected,
    isQuestionsEnded,
    isRecheckStep,
    isShowAnswersStep,
    isResultsStep,
    isBlitzStep,
    isGameEnded,
  } = sessionStore;

  if (!isStarted) {
    return <StartGame />;
  }

  if (isGameEnded) {
    return <Results isModeratorView />;
  }

  if (isResultsStep) {
    return <Results isModeratorView />;
  }

  if (isBlitzStep) {
    if (isRecheckStep) {
      return <RecheckBlitzAnswers isModerator />;
    }

    if (isShowAnswersStep) {
      return <ShowBlitzAnswers isModerator />;
    }

    if (!isQuestionsEnded) {
      return <BlitzQuestion isModerator />;
    }
  }

  if (isRecheckStep) {
    return <RecheckAnswers isModerator />;
  }

  if (isShowAnswersStep) {
    return <ShowAnswers isModerator />;
  }

  if (!isThemesSelected) {
    return <Themes isModerator />;
  }

  if (!isQuestionsEnded) {
    return <Question isModerator />;
  }

  if (isQuestionsEnded) {
    if (isShowAnswersStep) {
      return <RecheckAnswers isModerator />;
    }

    return <div />;
  }

  return <div />;
};

export default observer(GameContent);
