import React, { FC } from 'react';

import Grid from '@mui/material/Grid';

// Components
import ViewerContent from './ViewerContent';

// Styles
import classes from './Viewer.module.scss';

const Viewer: FC = () => {
  return (
    <div className={classes.Container}>
      <Grid
        container
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center"
        flexWrap="nowrap"
        className={classes.Grid}
      >
        <ViewerContent />
      </Grid>
    </div>
  );
};

export default Viewer;
