import React, { useCallback, useContext, FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'components/Button';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Styles
import classes from './Themes.module.scss';

interface IProps {
  isModerator?: boolean;
}

const Themes: FC<IProps> = ({ isModerator }) => {
  const { socket } = useContext(SocketContext);
  const { game, allThemes } = sessionStore;

  const selectTheme = useCallback(
    (id: number) => {
      if (!isModerator) {
        return;
      }

      socket.emit(SocketEmitEvent.moderator_selectThemes, {
        data: { themeId: id },
      });

      sessionStore.updateData({ themeIndex: id });
    },
    [isModerator]
  );

  useEffect(() => {
    sessionStore.setQuestionStep(false);
  }, []);

  const gameThemeIds = game?.themes.map(({ id }) => id) || [];
  const finishedThemes =
    allThemes?.length && game?.themes?.length
      ? allThemes.filter(({ id }) => !gameThemeIds.includes(id)).slice(-6)
      : [];

  return (
    <>
      <h1>Темы</h1>
      <ul className={classes.FinishedThemeBgList}>
        {finishedThemes.map(({ id }) => (
          <li key={id} className={classes.FinishedThemeBgItem} />
        ))}
      </ul>
      <ul className={classes.FinishedThemeTextList}>
        {finishedThemes.map(({ id, title }) => (
          <li key={id} className={classes.FinishedThemeTextItem}>
            <span>{title}</span>
          </li>
        ))}
      </ul>
      <div className={classes.Themes}>
        {game?.themes
          ? game?.themes.map((theme) => (
              <div key={theme.id}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => selectTheme(theme.id)}
                  className={classes.Button}
                >
                  {theme.title}
                </Button>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export default observer(Themes);
