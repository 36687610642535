import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import Button from 'components/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import AddIcon from '@mui/icons-material/Add';

// Stores
import sessionStore from 'stores/sessionStore';

// Components
import TableRowView from 'components/Results/TableRowView';

// Context
import SocketContext from 'contexts/SocketContext';

// Types
import { TeamAnswers } from 'types/team';
import { SocketEmitEvent } from 'types/socket';

// Styles
import classes from './Results.module.scss';

interface TransformedData {
  [teamName: string]: {
    themes: TransformedAnswersByTheme;
    blitz: TeamAnswers;
    totalPoints: number;
  };
}

interface TransformedAnswersByTheme {
  [themeIndex: number]: TeamAnswers;
}

interface SavedData {
  teamName: string;
  rounds: number[];
  blitz: number;
}

interface IProps {
  isModeratorTab?: boolean;
  isModeratorView?: boolean;
}

const Results: FC<IProps> = ({ isModeratorTab, isModeratorView }) => {
  const [savedData, setSavedData] = useState<SavedData[]>([]);
  const [isEditMode, setEditMode] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [isNewTeamAdded, setNewTeamAdded] = useState(false);
  const [deletedTeam, setDeletedTeam] = useState<string | null>(null);

  const {
    teamNameAnswers,
    isGameEnded,
    teamNames,
    teamCount,
    selectedOrderTheme,
    allThemes,
    isBlitzStep,
  } = sessionStore;
  const { socket } = useContext(SocketContext);

  const handleSelect = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_showQuestion);
  }, []);

  const handleAdd = useCallback(() => {
    setEditMode((_isEditMode) => !_isEditMode);
  }, []);

  const onTeamNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNewTeamName(e.target.value);
    },
    []
  );

  const onRevert = useCallback(() => {
    setNewTeamName('');
    handleAdd();
  }, []);

  const transformedAnswers = useMemo(() => {
    const transformedData: TransformedData = {};

    if (!teamNameAnswers) {
      return null;
    }

    teamNames.forEach((teamName) => {
      if (teamName === deletedTeam) {
        return;
      }

      if (
        !Object.keys(transformedData).length ||
        Object.keys(transformedData).indexOf(teamName) < 0
      ) {
        transformedData[teamName] = {
          themes: {},
          blitz: {
            answers: [],
            points: 0,
          },
          totalPoints: 0,
        };
      }
    });

    const themeIndexes = Object.keys(teamNameAnswers.themes);

    Object.keys(teamNameAnswers.themes).forEach((themeIndex) => {
      Object.keys(teamNameAnswers.themes[Number(themeIndex)]).forEach(
        (teamName) => {
          if (teamName === deletedTeam) {
            return;
          }

          transformedData[teamName].themes[Number(themeIndex)] =
            teamNameAnswers.themes[Number(themeIndex)][teamName];

          transformedData[teamName].blitz = teamNameAnswers.blitz[teamName];

          transformedData[teamName].totalPoints +=
            teamNameAnswers.themes[Number(themeIndex)][teamName].points;

          themeIndexes.forEach((expectedThemeIndex) => {
            if (
              typeof transformedData[teamName].themes[
                Number(expectedThemeIndex)
              ] === 'undefined'
            ) {
              transformedData[teamName].themes[Number(expectedThemeIndex)] = {
                answers: [],
                points: 0,
              };
            }
          });
        }
      );
    });

    if (isBlitzStep) {
      teamNames.forEach((teamName) => {
        if (teamName === deletedTeam) {
          return;
        }

        transformedData[teamName].totalPoints +=
          teamNameAnswers.blitz[teamName].points;
      });
    }

    return transformedData;
  }, [teamNameAnswers, teamNames, isBlitzStep, isNewTeamAdded, deletedTeam]);

  const onAddTeam = useCallback(() => {
    if (!transformedAnswers || !newTeamName) {
      return;
    }

    teamNames.push(newTeamName.trim());

    setNewTeamAdded(true);

    handleAdd();
  }, [newTeamName]);

  const sendTeamCount = useCallback(
    (cTeamCount: number) => {
      socket.emit(SocketEmitEvent.moderator_showTeamCountResult, {
        data: {
          teamCount: cTeamCount,
        },
      });
    },
    [teamCount]
  );

  const onSave = useCallback(() => {
    if (!savedData && !isModeratorTab) {
      return;
    }

    if (newTeamName) {
      socket.emit(SocketEmitEvent.moderator_addNewTeam, {
        data: { teamName: newTeamName.trim() },
      });

      setNewTeamAdded(false);
    }

    if (deletedTeam) {
      socket.emit(SocketEmitEvent.moderator_removeManualTeam, {
        data: { teamName: deletedTeam },
      });

      setDeletedTeam(null);
    }

    socket.emit(SocketEmitEvent.moderator_saveResults, {
      data: {
        savedData,
      },
    });

    setSavedData([]);
  }, [savedData, newTeamName, teamNames, deletedTeam]);

  const sortedAnswersByTotalPoints = useMemo(() => {
    if (!transformedAnswers) {
      return null;
    }

    return Object.entries(transformedAnswers).sort(
      (firstValue, secondValue) => {
        const themeAnswers1Length = Object.values(firstValue[1].themes).length;
        const themeAnswers2Length = Object.values(secondValue[1].themes).length;

        if (
          firstValue[1].themes[themeAnswers1Length - 1] &&
          secondValue[1].themes[themeAnswers2Length - 1] &&
          secondValue[1].totalPoints === firstValue[1].totalPoints
        ) {
          if (
            firstValue[1].blitz &&
            secondValue[1].blitz &&
            firstValue[1].blitz.points !== secondValue[1].blitz.points
          ) {
            return secondValue[1].blitz.points - firstValue[1].blitz.points;
          }

          if (
            firstValue[1].themes[themeAnswers1Length - 2] &&
            secondValue[1].themes[themeAnswers2Length - 2] &&
            firstValue[1].themes[themeAnswers1Length - 1].points ===
              secondValue[1].themes[themeAnswers2Length - 1].points
          ) {
            if (
              firstValue[1].themes[themeAnswers1Length - 3] &&
              secondValue[1].themes[themeAnswers2Length - 3] &&
              firstValue[1].themes[themeAnswers1Length - 2].points ===
                secondValue[1].themes[themeAnswers2Length - 2].points
            ) {
              if (
                firstValue[1].themes[themeAnswers1Length - 4] &&
                secondValue[1].themes[themeAnswers2Length - 4] &&
                firstValue[1].themes[themeAnswers1Length - 3].points ===
                  secondValue[1].themes[themeAnswers2Length - 3].points
              ) {
                if (
                  firstValue[1].themes[themeAnswers1Length - 5] &&
                  secondValue[1].themes[themeAnswers2Length - 5] &&
                  firstValue[1].themes[themeAnswers1Length - 4].points ===
                    secondValue[1].themes[themeAnswers2Length - 4].points
                ) {
                  return (
                    secondValue[1].themes[themeAnswers2Length - 5].points -
                    firstValue[1].themes[themeAnswers1Length - 5].points
                  );
                }

                return (
                  secondValue[1].themes[themeAnswers2Length - 4].points -
                  firstValue[1].themes[themeAnswers1Length - 4].points
                );
              }

              return (
                secondValue[1].themes[themeAnswers2Length - 3].points -
                firstValue[1].themes[themeAnswers1Length - 3].points
              );
            }

            return (
              secondValue[1].themes[themeAnswers2Length - 2].points -
              firstValue[1].themes[themeAnswers1Length - 2].points
            );
          }

          return (
            secondValue[1].themes[themeAnswers2Length - 1].points -
            firstValue[1].themes[themeAnswers1Length - 1].points
          );
        }

        return secondValue[1].totalPoints - firstValue[1].totalPoints;
      }
    );
  }, [transformedAnswers, isNewTeamAdded]);

  const onNextTeamClick = useCallback(() => {
    if (
      sortedAnswersByTotalPoints &&
      teamCount < sortedAnswersByTotalPoints.length
    ) {
      if (sortedAnswersByTotalPoints.length - teamCount === 4) {
        sendTeamCount(teamCount + 2);

        return;
      }

      if (sortedAnswersByTotalPoints.length - teamCount > 3) {
        sendTeamCount(teamCount + 3);

        return;
      }

      sendTeamCount(teamCount + 1);
    }
  }, [teamCount]);

  const theme = useCallback(
    (themeIndex: number): string | undefined => {
      const themeId = selectedOrderTheme[themeIndex || 0];

      return allThemes?.find((cTheme) => cTheme.id === themeId)?.title;
    },
    [selectedOrderTheme]
  );

  useEffect(() => {
    if (
      isModeratorView &&
      isGameEnded &&
      sortedAnswersByTotalPoints &&
      teamCount === 0
    ) {
      onNextTeamClick();
    }
  }, []);

  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      flex="1"
      className={cx(classes.Grid, {
        [classes.TabGrid]: isModeratorTab,
      })}
    >
      <div>
        {isModeratorTab ? <h1>Результаты</h1> : null}
        {isGameEnded && !isModeratorTab ? <h1>Итоги игры</h1> : null}
        {!isGameEnded && !isModeratorTab ? (
          <h1>Промежуточные результаты</h1>
        ) : null}
      </div>
      {teamNames && teamNames.length ? (
        <div className={classes.TableContainer}>
          <Table className={classes.Table}>
            <TableHead>
              <TableRow>
                {isModeratorTab ? (
                  <TableCell
                    className={cx(
                      classes.TableCell,
                      classes.TableCellHead,
                      classes.TableCellEdit
                    )}
                  />
                ) : null}
                <TableCell
                  className={cx(classes.TableCell, classes.TableCellHead)}
                >
                  Команда
                </TableCell>
                {teamNameAnswers
                  ? Object.keys(teamNameAnswers.themes).map((themeIndex) => (
                      <TableCell
                        className={cx(classes.TableCell, classes.TableCellHead)}
                        key={themeIndex}
                      >
                        {theme(Number(themeIndex))}
                      </TableCell>
                    ))
                  : null}
                {teamNameAnswers &&
                Object.keys(teamNameAnswers.blitz).length > 0 ? (
                  <TableCell
                    className={cx(classes.TableCell, classes.TableCellHead)}
                  >
                    Блиц
                  </TableCell>
                ) : null}
                <TableCell
                  className={cx(classes.TableCell, classes.TableCellHead)}
                >
                  Баллы
                </TableCell>
                <TableCell
                  className={cx(classes.TableCell, classes.TableCellHead)}
                >
                  Место
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAnswersByTotalPoints
                ? sortedAnswersByTotalPoints
                    .slice(
                      !isModeratorTab && isGameEnded
                        ? sortedAnswersByTotalPoints.length - teamCount
                        : 0,
                      sortedAnswersByTotalPoints.length
                    )
                    .map((value) => (
                      <TableRowView
                        key={value[0]}
                        teamName={value[0]}
                        place={
                          sortedAnswersByTotalPoints.findIndex(
                            (answer) => answer[0] === value[0]
                          ) + 1
                        }
                        themeAnswers={value[1].themes}
                        blitzAnswers={value[1].blitz}
                        setSavedData={setSavedData}
                        setDeletedTeam={setDeletedTeam}
                        savedData={savedData}
                        isModeratorTab={isModeratorTab}
                      />
                    ))
                : null}
              {isModeratorTab && !isNewTeamAdded ? (
                <TableRow className={classes.AddButtonWrapper}>
                  {isEditMode ? (
                    <>
                      <TableCell>
                        <div className={classes.IconWrapper}>
                          <IconButton aria-label="done" onClick={onAddTeam}>
                            <DoneAllIcon />
                          </IconButton>
                          <IconButton aria-label="revert" onClick={onRevert}>
                            <DoDisturbAltIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.CellButtonWrapper}>
                          <TextField
                            type="text"
                            InputLabelProps={{ shrink: true }}
                            onChange={onTeamNameChange}
                          />
                        </div>
                      </TableCell>
                      <TableCell colSpan={11} />
                    </>
                  ) : (
                    <TableCell
                      className={classes.AddItemCell}
                      onClick={handleAdd}
                      colSpan={11}
                    >
                      <AddIcon />
                    </TableCell>
                  )}
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </div>
      ) : (
        <p className={classes.NoneParticipants}>
          Участники еще не подключились
        </p>
      )}
      {isModeratorTab && teamNames && teamNames.length ? (
        <Button
          variant="contained"
          disabled={savedData.length === 0 && !isNewTeamAdded && !deletedTeam}
          onClick={onSave}
        >
          Сохранить
        </Button>
      ) : null}
      {isModeratorView && !isGameEnded ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.Button}
          onClick={handleSelect}
        >
          Продолжить
        </Button>
      ) : null}
      {isModeratorView &&
      isGameEnded &&
      sortedAnswersByTotalPoints &&
      teamCount < sortedAnswersByTotalPoints?.length ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.Button}
          onClick={onNextTeamClick}
        >
          Показать команды
        </Button>
      ) : null}
    </Grid>
  );
};

export default observer(Results);
