import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Types
import { TeamAnswers } from 'types/team';

// Stores
import sessionStore from 'stores/sessionStore';

// Styles
import DeleteIcon from '@mui/icons-material/Delete';
import classes from './TableRowView.module.scss';

interface TransformedAnswersByTheme {
  [themeIndex: number]: TeamAnswers;
}

interface SavedData {
  teamName: string;
  rounds: number[];
  blitz: number;
}

interface RowProps {
  teamName: string;
  themeAnswers: TransformedAnswersByTheme;
  place: number;
  blitzAnswers?: TeamAnswers;
  setSavedData: (
    value: SavedData[] | ((prevVal: SavedData[]) => SavedData[])
  ) => void;
  setDeletedTeam: (
    value: string | null | ((prevVal: string | null) => string | null)
  ) => void;
  savedData: SavedData[];
  isModeratorTab?: boolean;
}

const TableRowView: FC<RowProps> = ({
  teamName,
  themeAnswers,
  place,
  blitzAnswers,
  setSavedData,
  setDeletedTeam,
  savedData,
  isModeratorTab = false,
}) => {
  const [isEditMode, setEditMode] = useState(false);
  const [points, setPoints] = useState<number[]>(
    Object.values(themeAnswers).map((answer: TeamAnswers) => answer.points)
  );
  const [blitzPoints, setBlitzPoints] = useState<number>(
    blitzAnswers?.points || 0
  );

  const { isBlitzStep, manualTeams } = sessionStore;

  useEffect(() => {
    setPoints(
      Object.values(themeAnswers).map((answer: TeamAnswers) => answer.points)
    );
  }, [themeAnswers]);

  useEffect(() => {
    setBlitzPoints(blitzAnswers?.points || 0);
  }, [blitzAnswers]);

  const isManualTeam = useMemo(() => {
    return manualTeams.includes(teamName);
  }, [manualTeams]);

  const onToggleEditMode = useCallback((cIsEditMode: boolean) => {
    setEditMode(!cIsEditMode);
  }, []);

  const onSave = useCallback(
    (cIsEditMode: boolean, cPoints: number[], cBlitzPoints: number) => {
      setBlitzPoints(cBlitzPoints);
      setPoints(cPoints);
      setEditMode(!cIsEditMode);

      const indexData = savedData.findIndex(
        (data) => data.teamName === teamName
      );

      if (indexData > -1) {
        const copy = [...savedData];

        copy[indexData] = { teamName, rounds: cPoints, blitz: cBlitzPoints };
        setSavedData(copy);

        return;
      }

      setSavedData([
        ...savedData,
        { teamName, rounds: cPoints, blitz: cBlitzPoints },
      ]);
    },
    [points, blitzPoints, savedData, isEditMode]
  );

  const handleDelete = useCallback(() => {
    setDeletedTeam(teamName);
  }, []);

  const onRevert = () => {
    onToggleEditMode(isEditMode);
  };

  const sumPoints = useMemo(
    () =>
      points.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        blitzPoints
      ),
    [points, blitzPoints]
  );

  const onPointChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    themeIndex: number
  ) => {
    const copy: number[] = Object.assign([], points);

    copy[themeIndex] = Number(e.target.value);

    setPoints(copy);
  };

  const onPointIncrease = (themeIndex: number, isIncreased: boolean) => {
    const copy: number[] = Object.assign([], points);

    if (isIncreased) {
      copy[themeIndex] += 1;
      setPoints(copy);

      return;
    }

    copy[themeIndex] -= 1;
    setPoints(copy);
  };

  const onBlitzPointIncrease = (isIncreased: boolean) => {
    if (isIncreased) {
      setBlitzPoints((copy) => copy + 1);

      return;
    }

    setBlitzPoints((copy) => copy - 1);
  };

  const onBlitzPointChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBlitzPoints(Number(e.target.value));
  };

  return (
    <TableRow className={classes.TableRow}>
      {isModeratorTab ? (
        <TableCell className={cx(classes.TableCell, classes.TableCellEdit)}>
          {isEditMode ? (
            <div className={classes.IconWrapper}>
              <IconButton
                aria-label="done"
                onClick={() => onSave(isEditMode, points, blitzPoints)}
              >
                <DoneAllIcon />
              </IconButton>
              <IconButton aria-label="revert" onClick={onRevert}>
                <DoDisturbAltIcon />
              </IconButton>
            </div>
          ) : (
            <div className={classes.IconWrapper}>
              <IconButton
                aria-label="delete"
                onClick={() => onToggleEditMode(isEditMode)}
              >
                <EditIcon />
              </IconButton>
              {isManualTeam ? (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              ) : null}
            </div>
          )}
        </TableCell>
      ) : null}

      <TableCell className={classes.TableCell}>{teamName}</TableCell>

      {Object.keys(themeAnswers).map((themeIndex) => (
        <TableCell className={classes.TableCell} key={themeIndex}>
          {isEditMode ? (
            <div className={classes.CellButtonWrapper}>
              <TextField
                type="number"
                InputLabelProps={{ shrink: true }}
                value={points[Number(themeIndex)]}
                onChange={(e) => onPointChange(e, Number(themeIndex))}
              />
              <div className={classes.IconWrapper}>
                <IconButton
                  onClick={() => onPointIncrease(Number(themeIndex), true)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  onClick={() => onPointIncrease(Number(themeIndex), false)}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            points[Number(themeIndex)]
          )}
        </TableCell>
      ))}

      {typeof blitzAnswers !== 'undefined' && isBlitzStep ? (
        <TableCell className={classes.TableCell}>
          {isEditMode ? (
            <div className={classes.CellButtonWrapper}>
              <TextField
                type="number"
                InputLabelProps={{ shrink: true }}
                value={blitzPoints}
                onChange={(e) => onBlitzPointChange(e)}
              />
              <div className={classes.IconWrapper}>
                <IconButton onClick={() => onBlitzPointIncrease(true)}>
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton onClick={() => onBlitzPointIncrease(false)}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </div>
            </div>
          ) : (
            blitzPoints
          )}
        </TableCell>
      ) : null}

      <TableCell className={classes.TableCell}>{sumPoints}</TableCell>
      <TableCell className={classes.TableCell}>{place}</TableCell>
    </TableRow>
  );
};

export default observer(TableRowView);
