import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Outlet } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cx from 'clsx';

import Modal from 'components/Modal';
import Box from '@mui/material/Box';
import Button from 'components/Button';
import Typography from '@mui/material/Typography';

import PersonIcon from 'components/PersonIcon';
import LogoutIcon from 'components/LogoutIcon';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';
import authStore from 'stores/authStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Styles
import classes from './ViewerLayout.module.scss';

const ViewerLayout: FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { socket } = useContext(SocketContext);
  const navigate = useNavigate();

  const { teamNames } = sessionStore;
  const { isLogin } = authStore;

  const signIn = useCallback(() => {
    navigate('/viewer/login', {
      replace: true,
    });
  }, []);

  const handleOpen = useCallback(() => setModalOpen(true), []);
  const handleClose = useCallback(() => setModalOpen(false), []);
  const handleClear = useCallback(() => {
    sessionStore.clearGame();

    socket.disconnect();
    handleClose();

    navigate('/', {
      replace: true,
    });
  }, [socket]);

  const handleLogout = useCallback(() => {
    authStore.logout();

    navigate('/viewer', {
      replace: true,
    });

    socket.disconnect();
    handleClose();
  }, [socket]);

  const handleSessionLeft = useCallback(() => {
    sessionStore.clearGame();

    navigate('/', {
      replace: true,
    });

    socket.disconnect();
    handleClose();
  }, [socket]);

  useEffect(() => {
    if (socket?.isConnected) {
      socket.emit(SocketEmitEvent.player_localDate, {
        data: { localDate: new Date().toISOString() },
      });
    }
  }, [socket]);

  return (
    <span className={cx(classes.Wrapper, 'bg')}>
      <Helmet title="Режим зрителя" />
      <div className={classes.Container}>
        <div className={classes.HeaderContainer}>
          {!isLogin ? (
            <Button
              variant="contained"
              className={classes.SignIn}
              onClick={signIn}
            >
              Войти
            </Button>
          ) : null}
          <div className={classes.LogoutGame} onClick={handleOpen}>
            <LogoutIcon />
          </div>
          <div className={classes.ParticipantsCount}>
            <PersonIcon />
            {teamNames.length}
          </div>
        </div>
        <Outlet />
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {isLogin ? (
            <Box className={classes.Modal}>
              <div className={classes.Buttons}>
                <Button onClick={handleLogout}>Выйти из профиля</Button>
                <Button onClick={handleSessionLeft}>Выйти из сессии</Button>
              </div>
            </Box>
          ) : (
            <Box className={classes.TextModal}>
              <Typography variant="h6" component="h2">
                Вы уверены, что хотите выйти из этой сессии?
              </Typography>
              <div className={cx(classes.Buttons, classes.ButtonsRow)}>
                <Button onClick={handleClear}>Да</Button>
                <Button onClick={handleClose}>Нет</Button>
              </div>
            </Box>
          )}
        </Modal>
      </div>
    </span>
  );
};

export default observer(ViewerLayout);
