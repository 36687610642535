import { styled } from '@mui/material/styles';
import MuiInput from '@mui/material/Input';

const Input = styled(MuiInput)(() => ({
  color: '#fff',

  '&:before': {
    borderBottomColor: '#E2A41D',
    borderBottomWidth: 5,
  },
  '&:hover:not(.Mui-disabled):before': {
    borderBottomColor: '#D38B00',
    borderBottomWidth: 5,
  },
  '&:after': {
    borderBottomColor: '#E2B000',
    borderBottomWidth: 5,
  },
}));

export default Input;
