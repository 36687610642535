import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Grid from '@mui/material/Grid';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Hooks
import { useSocket } from 'hooks/useSocket';

// Stores
import sessionStore from 'stores/sessionStore';
import Logo from '../../components/Logo';

// Components
import SetSessionKey from '../Viewer/components/SetSessionKey';

// Styles
import classes from './Home.module.scss';

const Home: FC = () => {
  const navigate = useNavigate();
  const { setSocket } = useContext(SocketContext);

  const socket = useSocket();

  useEffect(() => {
    setSocket(socket);
  }, [socket]);

  const [isViewerConnection, setViewerConnection] = useState<boolean>(false);
  const [sessionInput, setSessionInput] = useState<string>(
    localStorage.getItem('sessionId') || ''
  );

  const onPlayerStart = useCallback(() => navigate('player'), []);
  const onModeratorStart = useCallback(() => navigate('login'), []);
  const onViewerStart = useCallback(() => setViewerConnection(true), []);

  useEffect(() => {
    if (isViewerConnection && socket?.isConnected) {
      sessionStore.setSessionId(sessionInput);

      navigate('viewer');
    }
  }, [socket]);

  if (isViewerConnection) {
    return <SetSessionKey socket={socket} setSessionId={setSessionInput} />;
  }

  return (
    <div className={classes.Wrapper}>
      <Grid
        className={classes.Container}
        container
        direction="column"
        justifyContent="center"
        textAlign="center"
        gap="20px"
        height="100%"
      >
        <Logo className={classes.Logo} />
        <Button variant="contained" onClick={onModeratorStart}>
          Играть за модератора
        </Button>
        <Button variant="contained" onClick={onViewerStart}>
          Играть за зрителя
        </Button>
        <Button variant="contained" onClick={onPlayerStart}>
          Играть за игрока
        </Button>
      </Grid>
    </div>
  );
};

export default observer(Home);
