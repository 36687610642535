import dayjs from 'dayjs';
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import Button from 'components/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Components
import Timer from 'components/Timer';
import ImageView from 'components/ImageView';
import VideoView from 'components/VideoView';
import AudioView from 'components/AudioView';

// Context
import SocketContext from 'contexts/SocketContext';

// Stores
import sessionStore from 'stores/sessionStore';
import questionStore from 'stores/questionStore';
import authStore from 'stores/authStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Styles
import classes from './Question.module.scss';

interface IProps {
  isModerator?: boolean;
  isViewer?: boolean;
}

const Question: FC<IProps> = ({ isModerator, isViewer }) => {
  const { socket } = useContext(SocketContext);
  const { questionId } = useParams();

  const {
    themeIndex,
    questionIndex,
    questions,
    isQuestionSent,
    timerEndTime,
    timerPausedTime,
    isTimerFinished,
    selectedOrderTheme,
    allThemes,
    isLocalQuestionStep,
    game,
  } = sessionStore;

  const { question } = questionStore;
  const { isLogin } = authStore;

  useEffect(() => {
    if (questionId) {
      questionStore.getQuestion(questionId);
    }
  }, []);

  const timer = useMemo(() => {
    if (game?.questionTimer) {
      return game.questionTimer;
    }

    return typeof questionIndex === 'number' && questions[questionIndex]?.timer
      ? questions[questionIndex].timer
      : 60;
  }, [questionIndex, questions]);

  const theme = useMemo(() => {
    const themeId = selectedOrderTheme[themeIndex || 0];

    return allThemes?.find((cTheme) => cTheme.id === themeId);
  }, [selectedOrderTheme, themeIndex]);

  const handleSend = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_sendQuestion, {
      data: { isQuestionSent: true },
    });
  }, []);

  const handleStart = useCallback(() => {
    let remainingSeconds = timer;

    if (sessionStore.timerPausedTime) {
      remainingSeconds = dayjs(sessionStore.timerEndTime).diff(
        sessionStore.timerPausedTime,
        'seconds'
      );
    }

    socket.emit(SocketEmitEvent.moderator_timer, {
      data: {
        timerEndTime: dayjs().add(remainingSeconds, 'seconds').toISOString(),
        timerPausedTime: null,
        isTimerFinished:
          dayjs().diff(
            dayjs().add(remainingSeconds, 'seconds').toISOString(),
            'seconds'
          ) >= 0,
      },
    });
  }, [timer]);

  const handlePause = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_timer, {
      data: {
        timerEndTime: sessionStore.timerEndTime,
        timerPausedTime: dayjs().toISOString(),
        isTimerFinished: false,
      },
    });
  }, []);

  const handleNext = useCallback(() => {
    if (!isModerator) {
      return;
    }

    if (!isLocalQuestionStep) {
      sessionStore.setQuestionStep(!isLocalQuestionStep);

      return;
    }

    socket.emit(SocketEmitEvent.moderator_showQuestion);
  }, [isLocalQuestionStep]);

  const prevStep = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_back, {
      data: {
        step: 'question',
      },
    });
  }, []);

  const isTextOnly = useMemo(() => {
    return (
      !(questions[questionIndex || 0]?.image || question?.image) &&
      (isLogin
        ? !(questions[questionIndex || 0]?.sound || question?.sound)
        : true) &&
      (isLogin
        ? !(questions[questionIndex || 0]?.youtubeId || question?.youtubeId)
        : true) &&
      !(questions[questionIndex || 0]?.options || question?.options)
    );
  }, [questions, question, questionIndex]);

  return (
    <>
      <div className={classes.ArrowContainer}>
        {!isQuestionSent && questionIndex === 0 ? (
          <Button
            variant="contained"
            className={classes.BackButton}
            onClick={prevStep}
          >
            <ArrowBackIcon className={classes.IconArrow} />
            <span className={classes.DirectionButtonText}>Назад</span>
          </Button>
        ) : null}
      </div>
      {(questionIndex === 0 && !isQuestionSent && !isLocalQuestionStep) ||
      (!isLocalQuestionStep && isModerator) ? (
        <p className={classes.Title}>
          Раунд {(themeIndex || 0) + 1}: {theme ? theme.title : 'Тестовый'}
        </p>
      ) : null}
      {isLocalQuestionStep || !isModerator ? (
        <>
          <h1>
            РАУНД {(themeIndex || 0) + 1}: {theme?.title || 'Тестовый'}
          </h1>
          <div
            className={clsx(classes.Text, {
              [classes.OnlyImage]: !!(
                questions[questionIndex || 0]?.image || question?.image
              ),
              [classes.OnlyText]: isTextOnly,
            })}
          >
            <p className={classes.QuestionTitle}>
              вопрос {(questionIndex || 0) + 1}
            </p>
            {questions[questionIndex || 0]?.text || question?.text || null}
            {questions[questionIndex || 0]?.image || question?.image ? (
              <ImageView
                roundIndex={themeIndex}
                questionIndex={questionIndex}
                imageUrl={
                  questions[questionIndex || 0]?.image || question?.image
                }
                isModerator={isModerator}
                isViewer={isViewer}
              />
            ) : null}
          </div>
          {isLogin &&
          (questions[questionIndex || 0]?.sound || question?.sound) ? (
            <AudioView
              url={questions[questionIndex || 0]?.sound || question?.sound}
              isModerator={isModerator}
            />
          ) : null}
          {isLogin &&
          (questions[questionIndex || 0]?.youtubeId || question?.youtubeId) ? (
            <VideoView
              youtubeId={
                questions[questionIndex || 0]?.youtubeId || question?.youtubeId
              }
              isModerator={isModerator}
            />
          ) : null}
          {questions[questionIndex || 0]?.options || question?.options ? (
            <>
              <p>Варианты ответа: </p>
              <div className={classes.Grid}>
                {(
                  questions[questionIndex || 0]?.options ||
                  question?.options ||
                  undefined
                ).map((option, index) => (
                  <p key={option}>
                    {index + 1}. {option}
                  </p>
                ))}
              </div>
            </>
          ) : null}
        </>
      ) : null}
      <div className={classes.ButtonWrapper}>
        {isModerator && !isQuestionSent && isLocalQuestionStep ? (
          <Button variant="contained" onClick={handleSend}>
            Задать
          </Button>
        ) : null}
        {isModerator &&
        isLocalQuestionStep &&
        isQuestionSent &&
        (!timerEndTime || timerPausedTime) &&
        !isTimerFinished ? (
          <Button variant="contained" onClick={handleStart}>
            Таймер
          </Button>
        ) : null}
        {isModerator &&
        isLocalQuestionStep &&
        isQuestionSent &&
        timerEndTime &&
        !isTimerFinished &&
        !timerPausedTime ? (
          <Button variant="contained" onClick={handlePause}>
            Пауза
          </Button>
        ) : null}
        {isModerator &&
        (!isLocalQuestionStep || isQuestionSent) &&
        (!isLocalQuestionStep || isTimerFinished) ? (
          <Button variant="contained" onClick={handleNext}>
            Далее
          </Button>
        ) : null}
        {isQuestionSent && !isTimerFinished ? (
          <Timer
            seconds={timer}
            timerEndTime={timerEndTime}
            timerPausedTime={timerPausedTime}
            isTimerFinished={isTimerFinished}
          />
        ) : null}
      </div>
    </>
  );
};

export default observer(Question);
