import React, {
  Dispatch,
  SetStateAction,
  FC,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'components/Button';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Stores
import sessionStore from 'stores/sessionStore';

// Components
import TeamCard from '../TeamCard';

// Styles
import classes from './AnswersArray.module.scss';

interface AnswersArrayProps {
  themeIndex: number | 'blitz';
  setThemeIndex: Dispatch<SetStateAction<number | 'blitz' | undefined>>;
}

const AnswersArray: FC<AnswersArrayProps> = ({ themeIndex, setThemeIndex }) => {
  const [localTeamNum, setLocalTeamNum] = useState<number>(0);

  const { teamNameAnswers } = sessionStore;

  const goBack = useCallback(() => setThemeIndex(undefined), [setThemeIndex]);

  const nextTeam = useCallback(
    () => setLocalTeamNum(localTeamNum + 1),
    [localTeamNum]
  );

  const prevTeam = useCallback(
    () => setLocalTeamNum(localTeamNum - 1),
    [localTeamNum]
  );

  const answers = useMemo(() => {
    if (!teamNameAnswers && themeIndex) {
      return null;
    }

    if (themeIndex === 'blitz') {
      return teamNameAnswers?.blitz;
    }

    return teamNameAnswers?.themes[themeIndex];
  }, [teamNameAnswers, themeIndex]);

  if (!answers) {
    return null;
  }

  const dataLength = Object.keys(answers).length;

  return (
    <div className={classes.Wrapper}>
      <Button variant="contained" className={classes.Button} onClick={goBack}>
        <ArrowBackIcon className={classes.IconArrow} />
        Назад
      </Button>
      {localTeamNum !== 0 || localTeamNum + 1 !== dataLength ? (
        <div className={classes.ArrowContainer}>
          {localTeamNum !== 0 ? (
            <Button
              variant="contained"
              className={classes.BackButton}
              onClick={prevTeam}
            >
              <ArrowBackIcon className={classes.IconArrow} />
              <span className={classes.DirectionButtonText}>Пред команда</span>
            </Button>
          ) : null}
          {localTeamNum + 1 !== dataLength ? (
            <Button
              variant="contained"
              className={classes.ForwardButton}
              onClick={nextTeam}
            >
              <ArrowForwardIcon className={classes.IconArrow} />
              <span className={classes.DirectionButtonText}>След команда</span>
            </Button>
          ) : null}
        </div>
      ) : null}
      <div className={classes.Container}>
        {Object.entries(answers).map(([teamName, teamData]) => (
          <TeamCard
            key={teamName}
            teamName={teamName}
            themeIndex={themeIndex}
            localTeamNum={localTeamNum}
            data={teamData}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(AnswersArray);
