import axios from 'api/helpers/axios';
import { Buffer } from 'buffer';

const cacheData: {
  [roundIndexQuestionIndex: string]: {
    base64Url: string | null;
    promise: Promise<string> | null;
  };
} = {};

export const getBase64Cache = (
  roundIndex: number,
  questionIndex: number,
  loadingUrl: string
): Promise<string> | null | undefined => {
  const cacheKey = `${roundIndex}_${questionIndex}`;

  // if (cacheData[cacheKey]?.base64Url) {
  //   return cacheData[cacheKey].base64Url;
  // }

  if (cacheData[cacheKey]?.promise) {
    return cacheData[cacheKey].promise;
  }

  cacheData[cacheKey] = {
    base64Url: null,
    promise: new Promise((resolve, reject) => {
      axios
        .get(loadingUrl, {
          responseType: 'arraybuffer',
        })
        .then(({ data }: { data: string }) =>
          resolve(Buffer.from(data, 'binary').toString('base64'))
        )
        .catch(reject);
    }),
  };

  return cacheData[cacheKey].promise?.then((base64Url) => {
    cacheData[cacheKey] = {
      base64Url,
      promise: null,
    };

    return base64Url;
  });
};
