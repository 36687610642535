import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// Stores
import sessionStore from 'stores/sessionStore';

// Styles
import classes from './ListItem.module.scss';

interface IProps {
  themeIndex: number;
  setThemeIndex: Dispatch<SetStateAction<number | 'blitz' | undefined>>;
}

const ListItem: FC<IProps> = ({ themeIndex, setThemeIndex }) => {
  const { allThemes } = sessionStore;

  const selectTheme = useCallback(
    () => setThemeIndex(themeIndex),
    [themeIndex]
  );

  return (
    <Box className={classes.Box}>
      <ListItemButton
        alignItems="flex-start"
        className={classes.ListItemButton}
        onClick={selectTheme}
      >
        <ListItemText
          primary={`Тема ${themeIndex + 1}: ${
            typeof allThemes !== 'undefined' ? allThemes[themeIndex].title : ''
          }`}
          className={classes.Text}
        />
      </ListItemButton>
    </Box>
  );
};

export default ListItem;
