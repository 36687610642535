import React, { FC, useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Button from 'components/Button';

// Types
import { TeamAnswers } from 'types/team';
import { SocketEmitEvent } from 'types/socket';

// Stores
import sessionStore from 'stores/sessionStore';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Components
import AnswerCard from '../AnswerCard';

// Styles
import classes from './TeamCard.module.scss';

interface IProps {
  teamName: string;
  themeIndex: number | 'blitz';
  localTeamNum: number;
  data: TeamAnswers;
}

const TeamCard: FC<IProps> = ({
  teamName,
  themeIndex,
  localTeamNum,
  data: { answers, points: sourcePoints },
}) => {
  const {
    allThemes,
    blitzTheme,
    teamNameAnswers,
    selectedOrderTheme,
    isAnswerDataUpdated,
  } = sessionStore;
  const { socket } = useContext(SocketContext);

  const [points, setPoints] = useState<number>(sourcePoints || 0);

  const handleSave = useCallback(() => {
    if (isAnswerDataUpdated) {
      sessionStore.setIsAnswerDataUpdated(false);

      sessionStore.setTeamPoints(
        teamName,
        points,
        themeIndex === 'blitz' ? -1 : themeIndex
      );

      socket.emit(SocketEmitEvent.moderator_updateTeamResults, {
        data: {
          themeIndex: themeIndex === 'blitz' ? undefined : themeIndex,
          isBlitz: themeIndex === 'blitz',
          teamName,
          answers,
          points,
        },
      });
    }
  }, [isAnswerDataUpdated, teamNameAnswers, points, setPoints, answers]);

  const getRightAnswer = useCallback(
    (index: number): string | undefined => {
      if (!allThemes || !blitzTheme) {
        return '';
      }

      const themeId =
        typeof themeIndex === 'number' ? selectedOrderTheme[themeIndex] : null;

      if (themeId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return allThemes.find((theme) => theme.id === themeId)?.questions[index]
          .answer;
      }

      return blitzTheme.questions[index].answer;
    },
    [themeIndex, blitzTheme, allThemes]
  );

  return (
    <div
      className={classes.Slide}
      key={teamName}
      style={{ transform: `translateX(-${localTeamNum}00%)` }}
    >
      <h3>Команда: {teamName}</h3>

      <div className={classes.AnswerContainer}>
        {answers.length > 0 ? (
          answers.map((answer, index) => (
            <AnswerCard
              key={answer.questionId}
              data={answer}
              rightAnswer={getRightAnswer(index)}
              teamName={teamName}
              themeIndex={themeIndex}
              questionIndex={index}
              setPoints={setPoints}
              points={points}
            />
          ))
        ) : (
          <p className={classes.Text}>
            Игрок подключился позднее текущего раунда
          </p>
        )}
      </div>
      <Button
        disabled={!isAnswerDataUpdated}
        className={classes.Button}
        onClick={handleSave}
        variant="contained"
      >
        Сохранить
      </Button>
    </div>
  );
};

export default observer(TeamCard);
