import React, { ChangeEvent, FC, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'clsx';

import Input from 'components/Input';
import Button from 'components/Button';
import Grid from '@mui/material/Grid';

// Hooks
import { useSocket } from 'hooks/useSocket';

// Stores
import sessionStore from 'stores/sessionStore';
import authStore from 'stores/authStore';

// Styles
import classes from './Connection.module.scss';

const Connection: FC = () => {
  const [sessionInput, setSessionInput] = useState<string>(
    localStorage.getItem('sessionId') || ''
  );

  const { accessToken } = authStore;
  const { gameId } = sessionStore;

  const socket = useSocket();
  const navigate = useNavigate();

  const onSessionInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSessionInput(event.target.value);
    },
    []
  );

  const handleNext = useCallback(() => {
    const sessionId = sessionInput.trim();

    sessionStore.setSessionId(sessionId);

    socket.connect({
      ...(sessionId ? { sessionId } : null),
      ...(gameId ? { gameId } : null),
      token: accessToken as string,
    });

    navigate(`/`, {
      replace: true,
    });
  }, [sessionInput]);

  return (
    <Grid
      container
      flexDirection="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      padding="10px"
      className={cx(classes.Wrapper, 'bg')}
    >
      <div className={classes.Container}>
        <h1>Введите ключ сессии для подключения</h1>
        <div className={classes.Footer}>
          <p className={classes.Label}>введите ключ</p>
          <div>
            <Input
              fullWidth
              inputProps={{
                maxLength: 15,
              }}
              value={sessionInput}
              onChange={onSessionInputChange}
              className={classes.Input}
            />
          </div>
          {/* TODO: check for an existing session Id */}
          <Button
            variant="contained"
            disabled={!sessionInput}
            onClick={handleNext}
            className={classes.Button}
          >
            Подключиться к сеансу
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default Connection;
