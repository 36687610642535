import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// Stores
import sessionStore from 'stores/sessionStore';

// Components
import QuestionCard from '../QuestionCard';
import QuestionCardBlitz from '../QuestionCardBlitz';
import ListItem from './components/ListItem';

// Styles
import classes from './Questions.module.scss';

interface QuestionsProps {
  themeIndex: number | 'blitz' | undefined;
  setThemeIndex: Dispatch<SetStateAction<number | 'blitz' | undefined>>;
}

const Questions: FC<QuestionsProps> = ({ themeIndex, setThemeIndex }) => {
  const { allThemes } = sessionStore;

  const setThemeBlitz = useCallback(() => setThemeIndex('blitz'), []);
  const resetThemeIndex = useCallback(() => setThemeIndex(undefined), []);

  if (themeIndex === 'blitz') {
    return <QuestionCardBlitz resetThemeIndex={resetThemeIndex} />;
  }
  if (typeof themeIndex === 'number') {
    return (
      <QuestionCard themeIndex={themeIndex} resetThemeIndex={resetThemeIndex} />
    );
  }

  return (
    <Grid
      container
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      {allThemes ? (
        <div className={classes.ListItemContainer}>
          {allThemes.map((key, index) => (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <ListItem
              key={key.id}
              themeIndex={index}
              setThemeIndex={setThemeIndex}
            />
          ))}
          <Box className={classes.Box}>
            <ListItemButton
              alignItems="flex-start"
              className={classes.ListItemButton}
              onClick={setThemeBlitz}
            >
              <ListItemText primary="Блиц" className={classes.Text} />
            </ListItemButton>
          </Box>
        </div>
      ) : (
        <p className={classes.Text}>Игра еще не началась</p>
      )}
    </Grid>
  );
};

export default observer(Questions);
