import React, { FC, useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Hooks
import { useSocket } from 'hooks/useSocket';
import { useRealtime } from 'hooks/useRealtime';

// Stores
import sessionStore from 'stores/sessionStore';

// Components
import TeamConnection from 'components/TeamConnection';
import Question from 'components/Question';
import Results from 'components/Results';
import ShowBlitzAnswers from 'components/Blitz/ShowBlitzAnswers';
import Themes from 'components/Themes';
import ShowAnswers from 'components/ShowAnswers';
import QuestionsArray from 'components/QuestionsArray';
import BlitzQuestion from 'components/Blitz/BlitzQuestion/BlitzQuestion';

// Styles
import classes from './Viewer.module.scss';

const ViewerContent: FC = () => {
  const { setSocket } = useContext(SocketContext);
  const navigate = useNavigate();

  const {
    sessionId,
    questions,
    questionIndex,
    isStarted,
    isShowAnswersStep,
    isBlitzStep,
    isQuestionSent,
    isRecheckStep,
    isQuestionsEnded,
    isThemesSelected,
    isResultsStep,
    isGameEnded,
    blitzTheme,
    themeIndex,
    selectedOrderTheme,
    allThemes,
  } = sessionStore;

  const socket = useSocket();
  useRealtime(socket);

  const theme = useMemo(() => {
    const themeId = selectedOrderTheme[themeIndex || 0];

    return allThemes?.find((cTheme) => cTheme.id === themeId);
  }, [selectedOrderTheme, themeIndex]);

  useEffect(() => {
    setSocket(socket);
  }, [socket]);

  useEffect(() => {
    if (sessionId) {
      socket?.connect({
        sessionId,
      });
    } else {
      navigate('/', { replace: true });
    }
  }, [sessionId]);

  if (!isStarted) {
    return <TeamConnection isViewer />;
  }

  if (isGameEnded) {
    return <Results />;
  }

  if (isResultsStep) {
    return <Results />;
  }

  if (isBlitzStep && blitzTheme) {
    if (isRecheckStep) {
      return <QuestionsArray questions={blitzTheme?.questions} isViewer />;
    }

    if (isShowAnswersStep) {
      return <ShowBlitzAnswers />;
    }

    if (
      !isQuestionSent &&
      typeof questionIndex === 'number' &&
      questionIndex > 0
    ) {
      return <p className={classes.Text}>Ожидайте следующего вопроса</p>;
    }

    if (!isQuestionsEnded) {
      if (!isQuestionSent && questionIndex === 0) {
        return <p className={classes.Text}>Блиц</p>;
      }

      return <BlitzQuestion isViewer />;
    }
  }

  if (isRecheckStep) {
    return <QuestionsArray questions={questions} isViewer />;
  }

  if (isShowAnswersStep) {
    return <ShowAnswers />;
  }

  if (!isThemesSelected) {
    return <Themes />;
  }

  if (
    !isQuestionSent &&
    typeof questionIndex === 'number' &&
    questionIndex > 0
  ) {
    return <p className={classes.Text}>Ожидайте следующего вопроса</p>;
  }

  if (!isQuestionsEnded) {
    if (!isQuestionSent && questionIndex === 0) {
      return (
        <p className={classes.Text}>
          Раунд {(themeIndex || 0) + 1}: {theme ? theme.title : ''}
        </p>
      );
    }

    return <Question isViewer />;
  }

  if (isShowAnswersStep && isResultsStep) {
    return <Results />;
  }

  return <div />;
};

export default observer(ViewerContent);
