import { action, makeObservable, observable } from 'mobx';

// Types
import { IGame, SessionData } from 'types/game';
import { IQuestion } from 'types/question';
import { IBlitzTheme, ITheme } from 'types/theme';
import { DisconnectedTeam, TeamAnswerData, TeamsAnswerData } from 'types/team';

class SessionStore {
  @observable
  isLoading: boolean = false;

  @observable
  sessionId: string | null = localStorage.getItem('sessionId');

  @observable
  gameId: string | null = null;

  @observable
  teamName: string | null = null;

  @observable
  game?: IGame;

  @observable
  allThemes?: ITheme[];

  @observable
  questions: IQuestion[] = [];

  @observable
  blitzTheme: IBlitzTheme | null = null;

  @observable
  teamNames: string[] = [];

  @observable
  manualTeams: string[] = [];

  @observable
  teamNameAnswers: TeamsAnswerData | null = null;

  @observable
  teamAnswers: TeamAnswerData | null = null;

  @observable
  isGameIdSelected: boolean = false;

  @observable
  isStarted: boolean = false;

  @observable
  isThemesSelected: boolean = false;

  @observable
  isBlitzStep: boolean = false;

  @observable
  isQuestionSent: boolean = false;

  @observable
  isAnswerSent: boolean = false;

  @observable
  isQuestionsEnded: boolean = false;

  @observable
  isRecheckStep: boolean = false;

  @observable
  isResultsShown: boolean = false;

  @observable
  isResultsStep: boolean = false;

  @observable
  isShowAnswersStep: boolean = false;

  @observable
  isThemesEnded: boolean = false;

  @observable
  timerEndTime: string | null = null;

  @observable
  timerPausedTime: string | null = null;

  @observable
  isTimerFinished: boolean = false;

  @observable
  isGameEnded: boolean = false;

  @observable
  selectedOrderTheme: number[] = [];

  @observable
  themeIndex?: number;

  @observable
  questionIndex?: number;

  @observable
  teamCount: number = 0;

  @observable
  disconnectedTeam: DisconnectedTeam[] = [];

  @observable
  isTableAvailable: boolean = false;

  @observable
  isPlayersScoreChecking: boolean = false;

  @observable
  isLocalQuestionStep: boolean = false;

  @observable
  questionsLength: number = -1;

  @observable
  isAnswerDataUpdated: boolean = false;

  @observable
  mediaStatus: 'played' | 'paused' | 'ended' | 'disabled' = 'disabled';

  @observable
  mediaTime: number = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  updateData = ({ sessionId, ...session }: Partial<SessionData>): void => {
    this.isLoading = true;

    Object.entries(session).forEach(([key, data]) => {
      this[key as keyof SessionData] = data as never;
    });

    this.isLoading = false;

    if (typeof sessionId !== 'undefined') {
      this.setSessionId(sessionId);
    }
  };

  @action
  setSessionId = (sessionId?: string | null | undefined): void => {
    this.sessionId = sessionId || null;

    if (sessionId) {
      localStorage.setItem('sessionId', sessionId);
    } else {
      this.clearGame();
    }
  };

  @action
  setGameId = (gameId?: string | null | undefined): void => {
    this.gameId = gameId || null;
  };

  @action
  setTeamName = (teamName?: string | null | undefined): void => {
    this.teamName = teamName || null;

    if (teamName) {
      localStorage.setItem('teamName', teamName);
    } else {
      localStorage.removeItem('teamName');
    }
  };

  @action
  setTeamPoints = (
    teamName: string,
    points: number,
    themeIndex?: number
  ): void => {
    if (this.teamNameAnswers && typeof themeIndex !== undefined) {
      if (themeIndex && themeIndex >= 0) {
        this.teamNameAnswers.themes[themeIndex][teamName].points = points;
      }

      if (themeIndex && themeIndex < 0) {
        this.teamNameAnswers.blitz[teamName].points = points;
      }
    }
  };

  @action
  setCorrectAnswer = (
    teamName: string,
    questionId: number,
    isRightAnswer: boolean,
    isBlitz: boolean,
    points: number,
    themeIndex?: number
  ): void => {
    if (this.teamNameAnswers) {
      const index = (
        isBlitz
          ? this.teamNameAnswers.blitz
          : this.teamNameAnswers.themes[themeIndex || 0]
      )[teamName].answers.findIndex(
        (question) => question.questionId === questionId
      );

      (isBlitz
        ? this.teamNameAnswers.blitz
        : this.teamNameAnswers.themes[themeIndex || 0])[teamName].answers[
        index
      ].isAnswerRight = isRightAnswer;

      (isBlitz
        ? this.teamNameAnswers.blitz
        : this.teamNameAnswers.themes[themeIndex || 0])[teamName].points =
        points;
    }
  };

  @action
  setQuestionStep = (isQuestionStep: boolean) => {
    this.isLocalQuestionStep = isQuestionStep;
  };

  @action
  setIsAnswerDataUpdated = (isAnswerDataUpdated: boolean) => {
    this.isAnswerDataUpdated = isAnswerDataUpdated;
  };

  @action
  clearGame = () => {
    localStorage.removeItem('sessionId');

    this.gameId = null;
    this.sessionId = null;
  };

  @action
  reset = (): void => {
    this.setSessionId();
  };
}

const sessionStore = new SessionStore();

export default sessionStore;
