import React, { FC, useCallback, useState, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

import Button from 'components/Button';

// Components
import Timer from 'components/Timer';
import ImageView from 'components/ImageView';
import AudioView from 'components/AudioView';
import VideoView from 'components/VideoView';

// Stores
import sessionStore from 'stores/sessionStore';
import authStore from 'stores/authStore';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Types
import { SocketEmitEvent } from 'types/socket';

// Styles
import clsx from 'clsx';
import classes from './BlitzQuestion.module.scss';

interface IProps {
  isModerator?: boolean;
  isViewer?: boolean;
}

const BlitzQuestion: FC<IProps> = ({ isModerator, isViewer }) => {
  const [isLocalBlitzStep, setBlitzStep] = useState<boolean>(false);

  const { socket } = useContext(SocketContext);

  const {
    blitzTheme,
    questions,
    questionIndex,
    isQuestionSent,
    timerEndTime,
    timerPausedTime,
    isTimerFinished,
    isBlitzStep,
    game,
  } = sessionStore;
  const { isLogin } = authStore;

  const timer = useMemo(() => {
    if (typeof questionIndex !== 'number') {
      return 60;
    }

    if (game?.questionTimer) {
      return game.questionTimer;
    }

    if (isBlitzStep) {
      return blitzTheme?.questions[questionIndex].timer;
    }

    return questions[questionIndex].timer;
  }, [questionIndex, questions, isBlitzStep]);

  const handleSend = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_sendBlitz, {
      data: { isQuestionSent: true },
    });
  }, []);

  const handleStart = useCallback(() => {
    if (!isModerator) {
      return;
    }

    let remainingSeconds = timer || 60;

    if (sessionStore.timerPausedTime) {
      remainingSeconds = dayjs(sessionStore.timerEndTime).diff(
        sessionStore.timerPausedTime,
        'seconds'
      );
    }

    socket.emit(SocketEmitEvent.moderator_blitzTimer, {
      data: {
        timerEndTime: dayjs().add(remainingSeconds, 'seconds').toISOString(),
        timerPausedTime: null,
        isTimerFinished:
          dayjs().diff(
            dayjs().add(remainingSeconds, 'seconds').toISOString(),
            'seconds'
          ) >= 0,
      },
    });
  }, [timer]);

  const handlePause = useCallback(() => {
    if (!isModerator) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_blitzTimer, {
      data: {
        timerEndTime: sessionStore.timerEndTime,
        timerPausedTime: dayjs().toISOString(),
        isTimerFinished: false,
      },
    });
  }, []);

  const handleNext = useCallback((isCurBlitzStep: boolean) => {
    if (!isCurBlitzStep) {
      setBlitzStep(!isCurBlitzStep);

      return;
    }

    socket.emit(SocketEmitEvent.moderator_showBlitz);
  }, []);

  const isTextOnly = useMemo(() => {
    return (
      !blitzTheme?.questions[questionIndex || 0]?.image &&
      (isLogin ? !blitzTheme?.questions[questionIndex || 0]?.sound : true) &&
      (isLogin
        ? !blitzTheme?.questions[questionIndex || 0]?.youtubeId
        : true) &&
      !blitzTheme?.questions[questionIndex || 0]?.options
    );
  }, [isLogin, blitzTheme, questionIndex]);

  return (
    <>
      {(questionIndex === 0 && !isQuestionSent && !isLocalBlitzStep) ||
      (!isLocalBlitzStep && isModerator) ? (
        <p className={classes.Title}>Блиц</p>
      ) : null}
      {isLocalBlitzStep || !isModerator ? (
        <>
          <h1>БЛИЦ</h1>
          {blitzTheme && blitzTheme.questions[questionIndex || 0]?.text ? (
            <div
              className={clsx(classes.Text, {
                [classes.OnlyImage]:
                  !!blitzTheme?.questions[questionIndex || 0]?.image,
                [classes.OnlyText]: isTextOnly,
              })}
            >
              <p className={classes.QuestionTitle}>
                вопрос {(questionIndex || 0) + 1}
              </p>
              {blitzTheme?.questions[questionIndex || 0]?.text || null}
              {blitzTheme?.questions[questionIndex || 0]?.image ? (
                <ImageView
                  roundIndex={-1}
                  questionIndex={questionIndex}
                  imageUrl={blitzTheme?.questions[questionIndex || 0].image}
                  isModerator={isModerator}
                  isViewer={isViewer}
                />
              ) : null}
            </div>
          ) : null}
          {isLogin && blitzTheme?.questions[questionIndex || 0]?.sound ? (
            <AudioView
              url={blitzTheme.questions[questionIndex || 0]?.sound}
              isModerator={isModerator}
            />
          ) : null}
          {isLogin && blitzTheme?.questions[questionIndex || 0]?.youtubeId ? (
            <VideoView
              youtubeId={blitzTheme.questions[questionIndex || 0].youtubeId}
              isModerator={isModerator}
            />
          ) : null}
          {blitzTheme?.questions[questionIndex || 0]?.options ? (
            <>
              <p>Варианты ответа: </p>
              <div className={classes.Grid}>
                {(
                  blitzTheme.questions[questionIndex || 0]?.options || undefined
                ).map((option, index) => (
                  <p key={option}>
                    {index + 1}. {option}
                  </p>
                ))}
              </div>
            </>
          ) : null}
        </>
      ) : null}
      <div className={classes.ButtonWrapper}>
        {isModerator && !isQuestionSent && isLocalBlitzStep ? (
          <Button variant="contained" onClick={handleSend}>
            Задать
          </Button>
        ) : null}
        {isModerator &&
        isLocalBlitzStep &&
        isQuestionSent &&
        (!timerEndTime || timerPausedTime) &&
        !isTimerFinished ? (
          <Button variant="contained" onClick={handleStart}>
            Таймер
          </Button>
        ) : null}
        {isModerator &&
        isLocalBlitzStep &&
        isQuestionSent &&
        timerEndTime &&
        !isTimerFinished &&
        !timerPausedTime ? (
          <Button variant="contained" onClick={handlePause}>
            Пауза
          </Button>
        ) : null}
        {isModerator &&
        (!isLocalBlitzStep || isQuestionSent) &&
        (!isLocalBlitzStep || isTimerFinished) ? (
          <Button
            variant="contained"
            onClick={() => handleNext(isLocalBlitzStep)}
          >
            Далее
          </Button>
        ) : null}
        {isQuestionSent && !isTimerFinished ? (
          <div className={classes.Timer}>
            <Timer
              seconds={timer || 60}
              timerEndTime={timerEndTime}
              timerPausedTime={timerPausedTime}
              isTimerFinished={isTimerFinished}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default observer(BlitzQuestion);
