import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CircleControls from 'libs/react-player-circle-controls';
import { observer } from 'mobx-react-lite';

// Components
import Loading from 'components/Loading';

// Stores
import sessionStore from 'stores/sessionStore';

// Types
import { SocketEmitEvent } from 'types/socket';

// Contexts
import SocketContext from 'contexts/SocketContext';

import classes from './AudioView.module.scss';

interface IProps {
  url?: string;
  isModerator?: boolean;
  isUncontrollable?: boolean;
}

const AudioView: FC<IProps> = ({
  url,
  isModerator = false,
  isUncontrollable = false,
}) => {
  const player = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [playerState, setPlayerState] = useState({
    played: 0,
    loaded: 0,
  });

  const onSeek = (amount: number) => {
    if (player.current) {
      player.current.seekTo(amount, 'fraction');
    }
  };

  const onTogglePlaying = useCallback(
    () => setPlaying((cPlaying) => !cPlaying),
    []
  );

  const { mediaStatus, mediaTime } = sessionStore;

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (isUncontrollable || isModerator || !player.current) {
      return;
    }

    if (mediaStatus === 'played') {
      setPlaying(true);

      if (mediaTime > 0) {
        player.current.seekTo(mediaTime, 'seconds');
      }
    }

    if (mediaStatus === 'paused') {
      setPlaying(false);

      if (mediaTime > 0) {
        player.current.seekTo(mediaTime);
      }
    }

    if (mediaStatus === 'ended') {
      setPlaying(false);
    }
  }, [mediaStatus, mediaTime]);

  const handleAudioPlayed = useCallback(() => {
    if (isUncontrollable || !isModerator || !player.current) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_playMedia, {
      data: { status: 'played', time: player.current.getCurrentTime() },
    });
  }, [player.current]);

  const handleAudioPaused = useCallback(() => {
    if (isUncontrollable || !isModerator || !player.current) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_playMedia, {
      data: { status: 'paused', time: player.current.getCurrentTime() },
    });
  }, []);

  const handleAudioEnded = useCallback(() => {
    setPlaying(false);

    if (isUncontrollable || !isModerator) {
      return;
    }

    socket.emit(SocketEmitEvent.moderator_playMedia, {
      data: { status: 'ended' },
    });
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {typeof url === 'undefined' ? (
        <Loading />
      ) : (
        <div className={classes.Root}>
          <ReactPlayer
            ref={player}
            url={url}
            playing={playing}
            height="0"
            width="0"
            onPlay={handleAudioPlayed}
            onPause={handleAudioPaused}
            onProgress={setPlayerState}
            onEnded={handleAudioEnded}
          />
          <CircleControls
            played={playerState.played}
            loaded={playerState.loaded}
            playing={playing}
            onSeek={onSeek}
            onTogglePlaying={onTogglePlaying}
          />
        </div>
      )}
    </>
  );
};

export default observer(AudioView);
