import React, { FC, useCallback, useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

import Button from 'components/Button';

// Components
import Timer from 'components/Timer';

// Types
import { SocketEmitEvent } from 'types/socket';

// Stores
import sessionStore from 'stores/sessionStore';

// Contexts
import SocketContext from 'contexts/SocketContext';

// Styles
import classes from './RecheckBlitzAnswers.module.scss';

interface IProps {
  isModerator?: boolean;
}

const RecheckBlitzAnswers: FC<IProps> = ({ isModerator }) => {
  const { socket } = useContext(SocketContext);
  const { timerEndTime, timerPausedTime, isTimerFinished, game } = sessionStore;

  const timer = useMemo(() => {
    return game?.blitzTimerCount ? game.blitzTimerCount : 60;
  }, []);

  const handleStart = useCallback(() => {
    let remainingSeconds = timer;

    if (sessionStore.timerPausedTime) {
      remainingSeconds = dayjs(sessionStore.timerEndTime).diff(
        sessionStore.timerPausedTime,
        'seconds'
      );
    }

    socket.emit(SocketEmitEvent.moderator_timer, {
      data: {
        timerEndTime: dayjs().add(remainingSeconds, 'seconds').toISOString(),
        timerPausedTime: null,
        isTimerFinished:
          dayjs().diff(
            dayjs().add(remainingSeconds, 'seconds').toISOString(),
            'seconds'
          ) >= 0,
      },
    });
  }, [timer]);

  const handlePause = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_timer, {
      data: {
        timerEndTime: sessionStore.timerEndTime,
        timerPausedTime: dayjs().toISOString(),
        isTimerFinished: false,
      },
    });
  }, []);

  const handleNext = useCallback(() => {
    socket.emit(SocketEmitEvent.moderator_showBlitz, {
      data: { isShowTheAnswersStep: true },
    });
  }, []);

  return (
    <>
      <p className={classes.Text}>Перепроверка ответов</p>
      {isModerator && (!timerEndTime || timerPausedTime) && !isTimerFinished ? (
        <Button variant="contained" onClick={handleStart}>
          Таймер
        </Button>
      ) : null}
      {isModerator && timerEndTime && !isTimerFinished && !timerPausedTime ? (
        <Button variant="contained" onClick={handlePause}>
          Пауза
        </Button>
      ) : null}
      {isModerator && isTimerFinished ? (
        <Button variant="contained" onClick={handleNext}>
          Далее
        </Button>
      ) : null}
      {!isTimerFinished ? (
        <div className={classes.Timer}>
          <Timer
            seconds={timer}
            timerEndTime={timerEndTime}
            timerPausedTime={timerPausedTime}
            isTimerFinished={isTimerFinished}
          />
        </div>
      ) : null}
    </>
  );
};

export default observer(RecheckBlitzAnswers);
