import React, { FC } from 'react';

import classes from './Participants.module.scss';

interface IProps {
  teamNames: string[];
}

const Participants: FC<IProps> = ({ teamNames }) => {
  return (
    <div className={classes.Participants}>
      {teamNames.map((teamName) => (
        <div className={classes.Participant} key={teamName}>
          <p>{teamName}</p>
        </div>
      ))}
    </div>
  );
};

export default Participants;
